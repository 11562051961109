import React, { useState, useEffect } from 'react';
import { auth, db } from '../../backend/firebase';
import { collection, query, orderBy, where, onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';
import { Button, Dialog, DialogContent, DialogActions, TextField, Table, TableHead, TableRow, TableCell, TableBody } from '@mui/material';
import Title from '../Title/Title';
import TicketDetailsModal from '../TicketDetailsModal/TicketDetailsModal';

export default function UserTickets() {
  const [tickets, setTickets] = useState([]);
  const [openNewTicketDialog, setOpenNewTicketDialog] = useState(false);
  const [newTicket, setNewTicket] = useState({ subject: '', message1: '' });
  const [ticketDetailsModalOpen, setTicketDetailsModalOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const currentUserUID = auth.currentUser ? auth.currentUser.uid : null;

  useEffect(() => {
    if (!currentUserUID) return;

    const ticketsQuery = query(collection(db, "tickets"), orderBy("uid"), where("uid", ">=", currentUserUID), where("uid", "<=", `${currentUserUID}\uf8ff`));

    const unsubscribe = onSnapshot(ticketsQuery, (querySnapshot) => {
      const ticketsArray = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate().toLocaleString() || 'No Date'
      }));
      setTickets(ticketsArray);
    }, err => {
      console.error("Error fetching tickets:", err);
    });

    return () => unsubscribe();
  }, [currentUserUID]);

  const handleOpenNewTicketDialog = () => setOpenNewTicketDialog(true);

  const handleCloseNewTicketDialog = () => {
    setOpenNewTicketDialog(false);
    setNewTicket({ subject: '', message1: '' });
  };

  const handleCreateTicket = async () => {
    if (!currentUserUID || !newTicket.subject.trim() || !newTicket.message1.trim()) return;
  
    // Use a client-generated timestamp. This is less precise than serverTimestamp but avoids the issue.
    const timestamp = new Date(); // Generate a timestamp on the client
  
    await addDoc(collection(db, "tickets"), {
      uid: currentUserUID,
      subject: newTicket.subject,
      messages: [{
        message: newTicket.message1,
        sender: 'user',
        timestamp: timestamp, // Use the client-generated timestamp
      }],
      status: 'Open',
    });
  
    handleCloseNewTicketDialog(); // Reset dialog and state
  };
  
  
  
  

  const handleOpenTicketDetailsModal = (ticket) => {
    setSelectedTicket(ticket);
    setTicketDetailsModalOpen(true);
  };

  const handleCloseTicketDetailsModal = () => {
    setTicketDetailsModalOpen(false);
    setSelectedTicket(null);
  };

  return (
    <React.Fragment>
      <Title>My Tickets</Title>
      <Button variant="contained" color="primary" onClick={handleOpenNewTicketDialog}>Create New Ticket</Button>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.map((ticket) => (
            <TableRow key={ticket.id} hover onClick={() => handleOpenTicketDetailsModal(ticket)}>
              <TableCell>{ticket.date}</TableCell>
              <TableCell>{ticket.subject}</TableCell>
              <TableCell>{ticket.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      <Dialog open={openNewTicketDialog} onClose={handleCloseNewTicketDialog} maxWidth="sm" fullWidth>
        <DialogContent>
          <Title>Create New Ticket</Title>
          <TextField autoFocus margin="dense" id="subject" label="Subject" type="text" fullWidth variant="outlined" value={newTicket.subject} onChange={(e) => setNewTicket({ ...newTicket, subject: e.target.value })} />
          <TextField margin="dense" id="message1" label="Message" type="text" fullWidth variant="outlined" multiline rows={4} value={newTicket.message1} onChange={(e) => setNewTicket({ ...newTicket, message1: e.target.value })} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseNewTicketDialog}>Cancel</Button>
          <Button onClick={handleCreateTicket} color="primary">Send</Button>
        </DialogActions>
      </Dialog>

      {selectedTicket && (
        <TicketDetailsModal ticket={selectedTicket} open={ticketDetailsModalOpen} handleClose={handleCloseTicketDetailsModal} />
      )}
    </React.Fragment>
  );
}

