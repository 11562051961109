import React from 'react';
import './PricingPlan.scss';

const PricingPlan = ({ planName, price, features, isSelected, onClick }) => {
  return (
    <div className={`pricing-plan ${isSelected ? 'selected' : ''}`} onClick={onClick}>
      <div className="plan-details">
        <div className="price">{price}</div>
        <div className="plan-name">{planName}</div>
        <ul className="features">
          {features.map((feature, index) => (
            <li key={index}>{feature}</li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default PricingPlan;
