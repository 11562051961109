import React, { useState } from 'react';
import './FAQ.scss';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

const FAQItem = ({ question, answer }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className={`faq-item ${isOpen ? 'open' : ''}`} onClick={toggleOpen}>
      <div className="faq-question">
        <HelpOutlineIcon className="question-icon" />
        <span>{question}</span>
        <ExpandMoreIcon className="dropdown-icon" />
      </div>
      {isOpen && <div className="faq-answer">{answer}</div>}
    </div>
  );
};

const FAQ = () => {
  const faqData = [
    { 
      "question": "What is the purpose of the chatbot automating DMs?", 
      "answer": "The chatbot automates direct messages to streamline communication, provide instant responses, and enhance user experience." 
  },
  { 
      "question": "How does the chatbot work in automating DMs?", 
      "answer": "The chatbot uses predefined rules, triggers, and in cases AI algorithms to analyze incoming messages and respond based on predetermined criteria." 
  },
  { 
      "question": "What platforms or messaging apps does the chatbot support for DM automation?", 
      "answer": "The chatbot can be designed to work with various messaging platforms such as Facebook Messenger, Instagram, WhatsApp and more." 
  },
  { 
      "question": "Can the chatbot personalize messages in automated DMs?", 
      "answer": "Yes, the chatbot can be programmed to personalize messages based on user data and preferences, creating a more tailored and engaging interaction." 
  },
  { 
      "question": "Is user privacy maintained when using a chatbot for DM automation?", 
      "answer": "Absolutely. The chatbot adheres to strict privacy protocols, ensuring that user data is handled securely and in compliance with privacy regulations." 
  },
  { 
      "question": "How can users opt-out or unsubscribe from automated DMs?", 
      "answer": "Users typically have the option to opt-out by following a provided link or using a specific command. The chatbot respects user preferences and privacy choices." 
  },
  { 
      "question": "What type of information can the chatbot handle in automated DMs?", 
      "answer": "The chatbot can handle a variety of information, including answering frequently asked questions, providing updates, and guiding users through specific processes." 
  },
  { 
      "question": "Can the chatbot handle complex queries in automated DMs?", 
      "answer": "Yes, the chatbot is designed to handle both simple and complex queries through advanced natural language processing capabilities." 
  },
  { 
      "question": "How often are automated DMs sent to users?", 
      "answer": "The frequency of automated DMs can be customized based on user behavior, preferences, or specific campaign requirements. It's designed to avoid spamming and provide value." 
  },
  { 
      "question": "Can businesses integrate the chatbot with their existing CRM system?", 
      "answer": "Yes, the chatbot can often be integrated with CRM systems to ensure seamless communication and data synchronization between the chatbot and the business's existing tools." 
  },
  { 
      "question": "Is there human oversight in the chatbot's automated DMs?", 
      "answer": "Yes, depending on the setup, there can be human oversight to monitor interactions, intervene when necessary, and continuously improve the chatbot's performance." 
  },
  { 
      "question": "How can businesses measure the effectiveness of automated DMs?", 
      "answer": "Metrics such as response time, user engagement, conversion rates, and customer satisfaction can be monitored to assess the effectiveness of the chatbot's automated DMs." 
  },

    
  ];

  return (
    <div className="faq-container">
      {faqData.map((faq, index) => (
        <FAQItem key={index} question={faq.question} answer={faq.answer} />
      ))}
    </div>
  );
};

export default FAQ;
