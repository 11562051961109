import React, { useState, useEffect } from 'react';
import { auth } from '../../../backend/firebase'; 
import './Checkout.scss'; 

const Checkout = ({ stripeUrl }) => {
  const [userId, setUserId] = useState(null);

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(user => {
      if (user) {
        setUserId(user.uid);
      }
    });

    return () => unsubscribe();
  }, []);

  const handlePayment = () => {
    if (userId) {
      const checkoutUrl = `${stripeUrl}?client_reference_id=${userId}`;
      window.location.href = checkoutUrl;
    } else {
      console.error('User is not signed in');
    }
  };

  return (
    <div className="checkout-container">
      <button onClick={handlePayment} disabled={!userId || !stripeUrl} className="stripe-pay-btn">Pay with Stripe</button>
    </div>
  );
};

export default Checkout;
