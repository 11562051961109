import React from 'react';
import Navbar from '../../components/Navbar/Navbar'; 
import './Home.scss'; 
import chatImage from '../../images/chat.png'; // Path to the image
import Card from '../../components/Card/Card'; // Import the Card component
// Importing images
import facebook from '../../images/facebook.png';
import instagram from '../../images/instagram.png';
import whatsapp from '../../images/whatsapp.png';
import PricingPlan from '../../components/PricingPlan/PricingPlan'; // Import the PricingPlan component
import { useNavigate } from 'react-router-dom';
import chatbotImage from '../../images/chatbot2.png'; 
import { motion } from 'framer-motion';
import Footer from '../../components/Footer/Footer';
import phone from '../../images/phone.png'; 
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import Clients from '../../components/Clients/Clients'; // Import the Clients component
import FeatureCard from '../../components/FeatureCard/FeatureCard';
import FAQ from '../../components/FAQ/FAQ'; 
import CookieConsent from '../../components/cookies/CookieConsent'; // Import the CookieConsent component


// Importing images for section
import supportImage from '../../images/support.png';
import leadImage from '../../images/lead.png';
import salesImage from '../../images/sales.png';
import reservationImage from '../../images/reservation.png';
import feedbackImage from '../../images/feedback.png';
import trainingImage from '../../images/training.png';
import content from '../../images/content.png';
import ecommerce from '../../images/ecommerce.png';
import starIcon from '../../images/star.png';
import group from '../../images/Group.png';




const Home = () => {

  const navigate = useNavigate();

  const handleGetStartedClick = () => {
    navigate('/signin');
  };

  const handleViewDemoClick = () => {
    navigate('/demo');
  };



  const handleHireNowClick = () => {
    // Handle the click event for the Hire Now button
  };

  const handleContactClick = () => {
    window.location.href = 'mailto:a6@a6ai.ai'; 
  };


  const cardData = [
    {
      id: 1,
      title: "Drive Revenue Growth",
      description: "Drive Revenue Growth by generating more Leads and converting sales.",
      icon: starIcon
    },
    {
      id: 2,
      title: "Streamline Operations",
      description: "Streamline Operations to Reduce Labor Expenses.",
      icon: starIcon
    },
    {
      id: 3,
      title: "Strengthen Brand Credibility",
      description: "Strengthen Brand Credibility through Exceptional Customer Service.",
      icon: starIcon
    }
  ];


  return (
    <>
      <Navbar />
      {/* <CookieConsent /> */}
      <div className="home-container">

        <div className="left-section">
          <div className="revolutionizing">Revolutionizing</div>
          <h1 className="future-communication">The Future of Communication</h1>
          <p>Unleash the power of simplicity and speed! In a world where customers crave instant solutions,
            <br />
            that not only satisfies the desire for ease but eradicates human error.  <br />
            Elevate your operations with a solution that's as quick as it is flawless.
          </p>

          <div className="buttons">
            <button className="btn get-started" onClick={handleGetStartedClick}>Get Started <NavigateNextOutlinedIcon /></button>
            <button className="btn sign-in" onClick={handleViewDemoClick}>
              View Demo</button>
          </div>
        </div>
        {/* <div className="right-section">
          <img src={phone} alt="Chatbot" />
        </div> */}
      </div>

      {/* <div className="features-section">
        <Clients />
      </div> */}


      <div className="features-section">
        <h2 className="section-small-title">Replace & Assist</h2>
        <h1 className="section-large-title">Custom Built Chatbots for Every Objective</h1>
        <p className="section-description">
          Unlock the future of business with A6AI, where innovation meets customization.</p>
        <p className="section-description"> Our bespoke chatbots are meticulously crafted for every conceivable objective.</p>
        <p className="section-description">         Allow your staff to focus on more complex and personalized interactions.
        </p>

        <div className="features-row">
          <FeatureCard icon={supportImage} title="Customer Support" text="Providing 24/7 assistance to address customer queries instantly." />
          <FeatureCard icon={leadImage} title="Lead Generation" text="Captivating visitors to gather leads and acquire essential contact details." />
          <FeatureCard icon={salesImage} title="Sales Assistance" text="Assisting customers seamlessly through the entire purchasing journey." />
          <FeatureCard icon={content} title="Content Distribution" text="Deliver curated content, such as articles, blog posts, videos, etc." />

        </div>
        <div className="features-row">
          <FeatureCard icon={reservationImage} title="Reservations" text="Efficiently managing and organizing bookings and appointment schedules." />
          <FeatureCard icon={feedbackImage} title="Feedback Collection" text="Streamlining the process of gathering valuable customer feedback through automation." />
          <FeatureCard icon={trainingImage} title="Staff Training" text="Ensuring employees have convenient access to training materials for continuous learning." />
          <FeatureCard icon={ecommerce} title="E-Commerce" text="Guide customers through their entire shopping experience." />

        </div>
      </div>


      <div className="new-section" >
        <h2 className="section-small-title colored-title">ELEVATE YOUR BUSINESS WITH A6Ai</h2>
        <h1 className="section-large-title">UNLOCK THE BENEFITS</h1>
        <p className="section-description">Embark on a journey into a new era of business efficiency with our <br />
          AI Chatbot, transforming customer interactions and operational dynamics. Witness <br />a surge in customer engagement through its instant response capabilities.</p>
        <p className="section-description"> More than just a tool, our chatbot stands as a strategic asset, minimizing operational<br /> costs, boostingsales, accelerating lead generation, and streamlining systems.</p>
       <div className="cards-row">
      {cardData.map((card) => (
        <div key={card.id} className="card">
          <img src={card.icon} alt="Star Icon" className="card-icon" />
          <div className="card-content">
            <strong>{card.title}</strong>
            <p>{card.description}</p>
          </div>
        </div>
      ))}
    </div>

      </div>


      <div className="remarkable-section">
        <div className="remarkable-content">
          <div className="remarkable-text">
            <div className="remarkable-title">The Remarkable Aspect?</div>
            <div className="remarkable-description">
              A6Ai is the true definition of a <strong>Workaholic</strong> - no sick days,<br />
              no vacations, no coffee breaks and no excuses. <br /> Operating round-the-clock, it ensures
              <strong> 24/7 </strong> availability at peak <br /> performance levels, delivering
              <strong> unparalleled efficiency</strong>.
            </div>
          </div>
          <button className="remarkable-get-started-btn" >Get Started</button>
        </div>
      </div>


      <div className="process-section">
      <div className="process-content">
        <div className="process-title">For Seamless Results</div>
        <div className="process-description">
          Our approach to crafting personalized chatbot experiences is a strategic and comprehensive process aimed at
          delivering tailored solutions that resonate with both clients and end-users.
        </div>
        <div className="process-diagram">
          <img src={group} alt="Process Diagram" />
        </div>
        <div className="process-footer">
          You will gain continuous access to our exclusive intelligence portal, where you and your team can effortlessly
          update content and request assistance, without any coding or IT knowledge. This invaluable resource empowers you
          to enact changes swiftly and seamlessly, ensuring optimal performance and facilitating efficient communication.
        </div>
      </div>
    </div>


      <div className="faq-section">
        <h2 className="section-small-title">Frequently Asked Questions</h2>
        <h1 className="section-large-title">Here you can find answers to some of the <br />most common questions we receive.</h1>
        <button className="btn faq-button" onClick={handleContactClick}>
          Contact Us <NavigateNextOutlinedIcon sx={{
            color: 'white',
            borderRadius: '50%',
            backgroundColor: '#150642', 
            fontSize: '1.2em', 
            padding: '2px'
          }} />
        </button>

        <div className="features-row">
          <FAQ />

        </div>

      </div>

      <Footer />


      {/* 
      <div id="product" className="cards-container">
        {cardData.map((card, index) => (
          <Card key={index} title={card.title} description={card.description} imageUrl={card.imageUrl} />
        ))}
      </div>

      <div id="pricing" className="pricing-container">
        {pricingPlans.map((plan, index) => (
          <PricingPlan key={index} planName={plan.planName} price={plan.price} features={plan.features} />
        ))}
      </div>



      <div id="tutorial" className='tutorial-cards'>
        <Footer />
      </div> */}
    </>
  );
};

export default Home;
