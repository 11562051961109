import React, { useState } from 'react';

// Material UI Icons
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MessageIcon from '@mui/icons-material/Message';
import ImageIcon from '@mui/icons-material/Image';
import LinkIcon from '@mui/icons-material/Link';
import EmailIcon from '@mui/icons-material/Email';
import LanguageIcon from '@mui/icons-material/Language';
import InfoIcon from '@mui/icons-material/Info';
import { useNavigate, useLocation } from 'react-router-dom';

// Firebase services
import { auth, db, storage } from '../../backend/firebase'; 
import { doc, setDoc } from 'firebase/firestore'; // Import for Firestore operations

import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage'; // Import for storage operations

// Joyride for tutorials
import Joyride, { STATUS } from 'react-joyride';

// Custom styles and components
import './customizationpage.scss';
import ChatbotPhone from '../../components/ChatbotPhone/ChatbotPhone';

// Tutorial images
import tutorial1 from '../../images/tutorial1.png';
import tutorial2 from '../../images/tutorial2.png';
import tutorial3 from '../../images/tutorial3.png';
import tutorial4 from '../../images/tutorial4.png';


export default function CustomizationPage() {


  const [showChatbot, setShowChatbot] = useState(false);

  const navigate = useNavigate(); // This will be used to navigate to the dashboard
  const location = useLocation(); // Use useLocation hook to access the query parameters

  const queryParams = new URLSearchParams(location.search);
  const initialPageId = queryParams.get('pageId');

  const [options, setOptions] = useState([]);
  const [subOptions, setSubOptions] = useState({});
  const [subSubOptions, setSubSubOptions] = useState({});
  const [businessType, setBusinessType] = useState('');
const [file, setFile] = useState(null);
const [isUploading, setIsUploading] = useState(false);


const handleFileChange = (event) => {
  const selectedFile = event.target.files[0];
  if (selectedFile && selectedFile.type === 'application/pdf') {
    setFile(selectedFile);
  } else {
    alert('Please upload a PDF file.');
  }
};



const handleProceedToDashboard = async () => {
  if (!businessName || !pageID || !file) {
    alert('Please fill all the fields and upload a PDF file.');
    return;
  }

  setIsUploading(true);
  const userId = auth.currentUser.uid; // Use the UID of the authenticated user
  const storageRef = ref(storage, `premium-users/${userId}/${file.name}`); // Use the UID in the storage path

  const uploadTask = uploadBytesResumable(storageRef, file);

  uploadTask.on(
    'state_changed',
    (snapshot) => {
      // Optional: Handle progress updates
    },
    (error) => {
      console.error('File upload error:', error);
      setIsUploading(false);
      alert('Error uploading file.');
    },
    () => {
      getDownloadURL(uploadTask.snapshot.ref).then(async (downloadURL) => {
        const userRef = doc(db, 'premium-users', userId); // Directly use the 'doc' function with UID
        await setDoc(userRef, {
          businessName,
          pageID,
          fileUrl: downloadURL,
          status: 'pending',
        });

        setIsUploading(false);
        navigate('/dashboard'); // Navigate to the dashboard
      });
    }
  );
};



  const updateOptionsForBusinessType = (type) => {
    switch (type) {
      case 'hotels':
        setOptions(['Rooms', 'Dining', 'Activities', 'Events', 'Locations']);
        setSubOptions({
          Rooms: ['Room Types', 'Rates', 'Availability', 'Promotions', 'Group Bookings'],
          Dining: ['Menu', 'Special Offers', 'Chefs', 'Reservations', 'Reviews'],
          Activities: ['Pool', 'Gym', 'Spa', 'Tours', 'Workshops'],
          Events: ['Weddings', 'Conferences', 'Meetings', 'Parties', 'Shows'],
          Locations: ['Locations']
        });
        setSubSubOptions({
          'Room Types': ['Single', 'Double', 'Suite', 'Deluxe', 'Presidential'],
          // ... other sub-sub-options here ...
        });
        break;
      case 'scratch':
        // Empty options for the 'Scratch' type
        setOptions([]);
        setSubOptions({});
        setSubSubOptions({});
        break;
      // Add more cases as needed for other business types
      default:
        // Reset or default setup
        setOptions([]);
        setSubOptions({});
        setSubSubOptions({});
    }
  };

  // Update the `setBusinessType` function to handle business type selection
  const handleBusinessTypeChange = (e) => {
    const newType = e.target.value;
    setBusinessType(newType);
    updateOptionsForBusinessType(newType);
  };

  

  const [selectedOption, setSelectedOption] = useState('');
  const [selectedSubOption, setSelectedSubOption] = useState('');
  const [selectedSubSubOption, setSelectedSubSubOption] = useState('');
  const [checkedOptions, setCheckedOptions] = useState({});



  const [newOption, setNewOption] = useState('');
  const [newSubOption, setNewSubOption] = useState('');
  const [newSubSubOption, setNewSubSubOption] = useState('');
  const [subSubOptionMessageTypes, setSubSubOptionMessageTypes] = useState({});


  // Initialize these state variables as empty strings to avoid uncontrolled to controlled component transition
  const [businessName, setBusinessName] = useState('');
  const [pageID, setPageID] = useState(initialPageId || '');






 



  

  const [rulesState, setRulesState] = useState({
    replyToStoryTagged: false,
    blockUserAfterInappropriateMessage: false,
  });

  const handleToggle = (rule) => {
    setRulesState({ ...rulesState, [rule]: !rulesState[rule] });
  };






  return (
    <div className="customization-container">

      <div className="customization-page">
        <div className="new-columns-container">
          <div className="first-column">
            <div className="page-id-card">
              <input
                type="text"
                placeholder="Page ID"
                value={pageID}
                onChange={(e) => setPageID(e.target.value)}
                className="page-id-input"
              />              <input
                type="text"
                placeholder="Business Name"
                value={businessName}
                onChange={(e) => setBusinessName(e.target.value)}
              />
            </div>
            <div className="rules-card">
              <h3>Rules</h3>
              <div className="rule">
                <p>Replay to Story (Tagged)</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={rulesState.replyToStoryTagged}
                    onChange={() => handleToggle('replyToStoryTagged')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
              <div className="rule">
                <p>Block User after Inappropriate Message</p>
                <label className="switch">
                  <input
                    type="checkbox"
                    checked={rulesState.blockUserAfterInappropriateMessage}
                    onChange={() => handleToggle('blockUserAfterInappropriateMessage')}
                  />
                  <span className="slider round"></span>
                </label>
              </div>
            </div>
          </div>

          <div className="second-column">
            <div className="business-type-container">
              <select
                className="business-type-select"
                value={businessType}
                onChange={handleBusinessTypeChange}
              >
                <option value="">Select Business Type</option>
                <option value="hotels">Hotels</option>
                <option value="scratch">Scratch</option>
                {/* Add other options as needed */}
              </select>
            </div>

            <div className="file-upload-container">
              <h3>Upload PDF File</h3>
              <div className="file-drop-area">
                <span className="file-drop-icon">📂</span> {/* Use an appropriate icon */}
                <p>Drop files here or click to upload</p>
                <input type="file" accept="application/pdf" onChange={handleFileChange} />
              </div>
            </div>
          </div>


        </div>




        <div className="submit-button-container">
        <button className="btn get-started" onClick={handleProceedToDashboard} disabled={isUploading}>
          {isUploading ? 'Uploading...' : 'Proceed to Dashboard'}
        </button>        </div>





        {showChatbot && (
          <div className="chatbot-container">
            <button className="close-chatbot" onClick={() => setShowChatbot(false)}>X</button>
            <ChatbotPhone docId={pageID || "0000000000"} />
          </div>
        )}



      </div>
    </div>
  );
}

