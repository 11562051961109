import React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ChatIcon from '@mui/icons-material/Chat'; // Icon for Engagement
import TicketIcon from '@mui/icons-material/ConfirmationNumber'; // Icon for Tickets
import SettingsIcon from '@mui/icons-material/Settings'; // Icon for Settings
import InstagramIcon from '@mui/icons-material/Instagram'; // Icon for Instagram

const DashboardListItems = ({ setSelectedSection }) => (
  <React.Fragment>
    <ListItemButton onClick={() => setSelectedSection('Engagement')}>
      <ListItemIcon><ChatIcon /></ListItemIcon>
      <ListItemText primary="Engagement" />
    </ListItemButton>
    <ListItemButton onClick={() => setSelectedSection('Tickets')}>
      <ListItemIcon>
        <TicketIcon />
      </ListItemIcon>
      <ListItemText primary="Tickets" />
    </ListItemButton>
    <ListItemButton onClick={() => setSelectedSection('Settings')}>
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Settings" />
    </ListItemButton>
    <ListItemButton onClick={() => setSelectedSection('Instagram')}>
      <ListItemIcon><InstagramIcon /></ListItemIcon>
      <ListItemText primary="Instagram" />
    </ListItemButton>
  </React.Fragment>
);

export default DashboardListItems;
