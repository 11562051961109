import React, { useState } from 'react';
import { Button, TextField, Paper, Grid, Typography, Snackbar } from '@mui/material';
import { db } from '../../backend/firebase'; 
import { doc, setDoc, getDoc } from 'firebase/firestore';

const TokenGenerator = () => {
  const [token, setToken] = useState('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const generateToken = () => {
    const newToken = Math.random().toString(36).substr(2, 9);
    setToken(newToken);
  };

  const checkTokenExists = async (newToken) => {
    const docRef = doc(db, "tokens", newToken);
    const docSnap = await getDoc(docRef);
    return docSnap.exists();
  };

  const saveTokenToFirestore = async () => {
    if (token) {
      const exists = await checkTokenExists(token);
      if (!exists) {
        const expiresAt = new Date();
        expiresAt.setDate(expiresAt.getDate() + 7); // Set token to expire in 7 days
        await setDoc(doc(db, "tokens", token), { isValid: true, expiresAt });
        setSnackbarMessage("Token saved to Firestore.");
      } else {
        setSnackbarMessage("Token already exists. Generate a new one.");
      }
      setOpenSnackbar(true);
    }
  };

  const handleCloseSnackbar = () => {
    setOpenSnackbar(false);
  };

  return (
    <Grid container spacing={2} justifyContent="center" alignItems="center" direction="column">
      <Grid item>
        <Typography variant="h5">Token Generator</Typography>
      </Grid>
      <Grid item>
        <Button variant="contained" onClick={generateToken}>Generate Token</Button>
      </Grid>
      {token && (
        <>
          <Grid item>
            <TextField value={token} variant="outlined" fullWidth InputProps={{ readOnly: true }} />
          </Grid>
          <Grid item>
            <Button variant="contained" color="primary" onClick={saveTokenToFirestore}>
              Save Token to Firestore
            </Button>
          </Grid>
        </>
      )}
      <Snackbar
        open={openSnackbar}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        message={snackbarMessage}
      />
    </Grid>
  );
};

export default TokenGenerator;
