import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth'; 
const AdminProtectedRoute = ({ children }) => {
  const { user, loading } = useAuth();
  const adminUID = "Dw0beJEWDoMtMoR1jPsMqkxqsXx2";

  if (loading) {
    return <div>Loading...</div>; 
  }

  if (!user) {
    return <Navigate to="/signin" replace />;
  }

  if (user.uid !== adminUID) {
    return <Navigate to="/signin" replace />;
  }

  return children;
};

export default AdminProtectedRoute;
