/* global FB */
import React from 'react';
import './ConnectFacebook.scss';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';

class ConnectFacebook extends React.Component {

    
    state = {
        pages: null, // State to hold the pages
    };

    componentDidMount() {
        this.checkLoginState();
    }

    checkLoginState = () => {
        if (window.FB) {
            FB.getLoginStatus(response => {
                this.statusChangeCallback(response);
            });
        } else {
            console.log("FB SDK not loaded yet. Waiting...");
    
            // Optionally retry after a delay
            setTimeout(this.checkLoginState, 1000); // Retry after 1 second
        }
    };
    
    statusChangeCallback = (response) => {
        if (response.status === 'connected') {
            this.fetchUserData();
            this.fetchPages();
        } else if (response.status === 'not_authorized') {
            console.log('Please log into this app.');
        } else {
            console.log('Please log into Facebook.');
        }
    };

    fetchUserData = () => {
        FB.api('/me', response => {
            console.log('Good to see you, ' + response.name + '.');
        });
    };

    fetchPages = () => {
        FB.api('/me/accounts', response => {
            if (response.data && response.data.length > 0) {
                this.setState({ pages: response.data });
    
                // Log each page's ID and access token to the console
                response.data.forEach(page => {
                    console.log(`Page Name: ${page.name}, Page ID: ${page.id}, Access Token: ${page.access_token}`);
                    // Here, you can also perform other actions with the page ID and access token
                });
            } else {
                this.setState({ pages: [] });
                console.log('You do not manage any Facebook pages.');
            }
        });
    };
    
    

    handleFacebookLogin = () => {
        FB.login(this.statusChangeCallback, { scope: 'email,public_profile,pages_manage_metadata' });
    };

    

    
    render() {
        const { pages, selectedPageId } = this.state;

        return (
            <div>
                <h1>Connect Your Facebook Page</h1>
                <button onClick={this.handleFacebookLogin}>Login with Facebook</button>

                {pages && (
                    <RadioGroup
                        name="pageSelection"
                        value={selectedPageId}
                        onChange={this.handlePageSelectionChange}
                    >
                        {pages.map(page => (
                            <FormControlLabel
                                key={page.id}
                                value={page.id}
                                control={<Radio />}
                                label={page.name}
                            />
                        ))}
                    </RadioGroup>
                )}

                {selectedPageId && (
                    <div>
                        <p>Selected Page ID: {selectedPageId}</p>
                        {/* Render more page details or options here */}
                    </div>
                )}
            </div>
        );
    }
}

export default ConnectFacebook;
