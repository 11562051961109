import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import Title from '../Title/Title'; 

function preventDefault(event) {
  event.preventDefault();
}

export function Deposits() {
  return (
    <React.Fragment>
      <Title>Revenue</Title>
      <Typography component="p" variant="h4">
        $3,024.00
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        on 15 March, 2024
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View balance
        </Link>
      </div>
    </React.Fragment>
  );
}

export function TotalEarnings() {
  return (
    <React.Fragment>
      <Title>Total Earnings</Title>
      <Typography component="p" variant="h4">
        $12,678.00
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        as of 15 March, 2024
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View details
        </Link>
      </div>
    </React.Fragment>
  );
}

export function TotalExpenses() {
  return (
    <React.Fragment>
      <Title>Total Expenses</Title>
      <Typography component="p" variant="h4">
        $5,432.00
      </Typography>
      <Typography color="text.secondary" sx={{ flex: 1 }}>
        as of 15 March, 2024
      </Typography>
      <div>
        <Link color="primary" href="#" onClick={preventDefault}>
          View breakdown
        </Link>
      </div>
    </React.Fragment>
  );
}
