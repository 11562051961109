import React, { useState, useEffect } from 'react';
import './Navbar.scss';
import logo from '../../images/logo.png'; 
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';

const Navbar = () => {
  const navigate = useNavigate();
  const handleGetStartedClick = () => {
    navigate('/signin');
  };
  const [scrolled, setScrolled] = useState(false);
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrolled(window.scrollY > 1);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const toggleMobileMenu = () => {
    setMobileMenuOpen(!mobileMenuOpen);
  };

  return (
    <nav className={`navbar ${scrolled ? 'scrolled' : ''}`}>
      <div className="navbar-logo">
        <a href="https://a6ai.ai">
          <img src={logo} alt="Logo" />
        </a>
      </div>

      <div className={`navbar-links ${mobileMenuOpen ? 'open' : ''}`}>
        <a href="https://a6ai.ai">HOME</a>
        <a href="/demo">DEMO</a>
      </div>

      <div className="navbar-signin">
        <a href="#signin" className="signin-button" onClick={handleGetStartedClick}>Sign In</a>
      </div>

      <div className="burger-menu" onClick={toggleMobileMenu}>
        <MenuIcon />
      </div>
    </nav>
  );
};

export default Navbar;
