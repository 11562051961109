import React, { useState } from 'react';
import './Demo.scss'; 
import Navbar from '../../components/Navbar/Navbar'; 
import Footer from '../../components/Footer/Footer';
import ChatbotPhone from '../../components/ChatbotPhone/ChatbotPhone';

const Demo = () => {
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <>
      <Navbar />
      <div className="demo-container">
        <h1 className="demo-title">Welcome to the Chatbot Customization Demo</h1>
        <p className="demo-headline">Experience the power of fully customizable chatbots for Facebook and Instagram right here.</p>
        <button onClick={toggleModal} className="modal-button">Open Chatbot Demo</button>

        {showModal && (
          <div className="modal">
            <div className="modal-content">
              <span className="close-button" onClick={toggleModal}>&times;</span>
              <ChatbotPhone docId="0000000000"/>
            </div>
          </div>
        )}
      </div>
      </>
  );
}

export default Demo;
