import React from 'react';
import './PrivacyPolicy.scss'; // Reusing the same SCSS file for consistent styling

function TermsAndConditions() {
    return (
        <div className="privacyPolicy"> {/* Reusing the same class for consistent styling */}
            <h1 className="title">Terms and Conditions</h1>
            <div className="content">
                <div className="rightColumn">
                    <p>Welcome to A6AI. By accessing and using our platform, you agree to comply with the following terms and conditions. Please read them carefully.</p>

                    <h2 className="section-small-title">1. Acceptance of Terms:</h2>
                    <p>By using A6AI, you agree to be bound by these terms and conditions as well as our Privacy Policy.</p>

                    <h2 className="section-small-title">2. Use of the Platform:</h2>
                    <p>You agree to use A6AI only for lawful purposes and in a way that does not infringe the rights of, restrict or inhibit anyone else's use and enjoyment of A6AI.</p>

                    <h2 className="section-small-title">3. Your Account:</h2>
                    <p>You are responsible for maintaining the confidentiality of your account and password and for restricting access to your computer.</p>

                    <h2 className="section-small-title">4. Intellectual Property:</h2>
                    <p>All content included on A6AI, such as text, graphics, logos, images, as well as the compilation thereof, is the property of A6AI or its content suppliers and protected by copyright laws.</p>

                    <h2 className="section-small-title">5. Termination:</h2>
                    <p>We may terminate your access to the platform, without cause or notice, which may result in the forfeiture and destruction of all information associated with your account.</p>

                    <h2 className="section-small-title">6. Changes to Terms:</h2>
                    <p>We reserve the right to modify or replace these Terms at any time. It is your responsibility to check the Terms periodically for changes.</p>

                    <h2 className="section-small-title">7. Contact Information:</h2>
                    <p>If you have any questions about these Terms, please contact us at a6@a6ai.ai.</p>
                </div>
            </div>
        </div>
    );
}

export default TermsAndConditions;
