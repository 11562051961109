import React, { useEffect, useState } from 'react';
import { db, auth } from '../../backend/firebase'; 
import { doc, getDoc, updateDoc, setDoc } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Switch, Modal, Box, Typography, Stepper, Step, StepLabel, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { Link as RouterLink } from 'react-router-dom';
import Title from '../Title/Title'; 
import { useNavigate } from 'react-router-dom';

const steps = [
  'Connect to Google',
  'Select Your Page',
  'Customize Your Chatbot'
];

const Settings = () => {
  const navigate = useNavigate();

  const [clientData, setClientData] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedPageId, setSelectedPageId] = useState('');
  const [pages, setPages] = useState([]);



  
  useEffect(() => {
    fetchClientData();
    loadFacebookSDK();
  }, []);

  const loadFacebookSDK = () => {
    window.fbAsyncInit = function() {
      window.FB.init({
        appId      : '940575337706229',
        cookie     : true,
        xfbml      : true,
        version    : 'v18.0'
      });
    };

    (function(d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  };


  const handleFacebookLogin = () => {
    window.FB.login(response => {
      if (response.status === 'connected') {
        fetchPages(response.authResponse.accessToken);
      } else {
        console.error('Failed to connect.');
      }
    }, { scope: 'email,public_profile,pages_show_list,pages_manage_metadata,pages_messaging,instagram_basic,instagram_manage_messages' });
  };


  const fetchPages = (accessToken) => {
    window.FB.api('/me/accounts', { access_token: accessToken }, (response) => {
      if (response && response.data) {
        const pageDetails = response.data.map(page => ({
          id: page.id,
          name: page.name,
          accessToken: page.access_token
        }));

        Promise.all(pageDetails.map(page => 
          new Promise((resolve) => {
            window.FB.api(`/${page.id}?fields=instagram_business_account`, { access_token: page.accessToken }, (igResponse) => {
              if (igResponse && igResponse.instagram_business_account) {
                resolve({ ...page, igPageId: igResponse.instagram_business_account.id });
              } else {
                resolve({ ...page, igPageId: null });
              }
            });
          })
        )).then(pagesWithIG => {
          setPages(pagesWithIG.filter(page => page.igPageId !== null));
        });
      } else {
        console.error('Error fetching pages:', response);
      }
    });
  };


  
  const fetchClientData = async () => {
    const currentUser = auth.currentUser;
    if (!currentUser) {
      console.error('No user is currently signed in.');
      return;
    }
    const userDocRef = doc(db, 'users', currentUser.uid);
    const userDocSnap = await getDoc(userDocRef);
    if (!userDocSnap.exists()) {
      console.error('User document does not exist.');
      return;
    }

    const pageIds = userDocSnap.data().pageIds || [];
    const clients = await Promise.all(pageIds.map(async ({ id, igPageId }) => {
      const clientDocRef = doc(db, 'IGclients', igPageId || id);
      const clientDocSnap = await getDoc(clientDocRef);
      if (clientDocSnap.exists()) {
        return { id: igPageId || id, ...clientDocSnap.data() };
      } else {
        return null;
      }
    }));
    setClientData(clients.filter(client => client !== null));
};




  const toggleClientActive = async (id, isActive) => {
    await updateDoc(doc(db, 'clients', id), { 'additionalInfo.isActive': !isActive });
    setClientData(clientData.map(client => client.id === id ? { ...client, additionalInfo: { ...client.additionalInfo, isActive: !isActive } } : client));
  };




  const exchangeTokenForLongLived = (shortLivedToken) => {
    const appId = '940575337706229';
    const appSecret = '3d93f81ede8975c85a29546050bd5fbb';
    return fetch(`https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=${appId}&client_secret=${appSecret}&fb_exchange_token=${shortLivedToken}`)
      .then(response => response.json())
      .then(data => {
        console.log('Long-lived token:', data.access_token); // Log the long-lived token
        return data.access_token;
      }).catch(error => {
        console.error('Failed to exchange token:', error);
      });
  };

  
  const handleNext = async () => {
    if (activeStep === 1 && (!selectedPageId || !pages.length)) {
      alert("Please select a page before proceeding.");
      return;
    }
  
    if (activeStep === 1) {
      const selectedPage = pages.find(page => page.id === selectedPageId);
      if (selectedPage) {
        const userDocRef = doc(db, 'users', auth.currentUser.uid);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
          let pageIds = userDocSnap.data().pageIds ? [...userDocSnap.data().pageIds] : [];
          const pageIndex = pageIds.findIndex(page => page.id === selectedPage.id);
  
          let newPageData = {
            id: selectedPage.id,
            name: selectedPage.name,
            accessToken: selectedPage.accessToken, // Use the existing token generated earlier
            igPageId: selectedPage.igPageId
          };
  
          if (pageIndex === -1) {
            // Page does not exist, add new one
            pageIds.push(newPageData);
          } else {
            // Page exists, update it with the new Instagram ID and ensure token is up-to-date
            pageIds[pageIndex] = { ...pageIds[pageIndex], igPageId: selectedPage.igPageId, accessToken: selectedPage.accessToken };
          }
  
          await updateDoc(userDocRef, {
            isConnectedToFacebook: true,
            pageIds: pageIds
          });
  
          console.log(`Page details saved successfully for ${selectedPage.name}`);
        } else {
          console.error("User document not found");
          navigate(`/`);
          return;
        }
      }
    }
  
    if (activeStep === steps.length - 1) {
      navigateBasedOnUserPlan();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };
  

  
  const navigateBasedOnUserPlan = async () => {
    const userDocRef = doc(db, "users", auth.currentUser.uid);
    const userDocSnap = await getDoc(userDocRef);
  
    if (userDocSnap.exists()) {
      const userData = userDocSnap.data();
      const selectedPage = pages.find(page => page.id === selectedPageId);

      // Check if the Facebook page exists in the clients collection
      const clientDocRef = doc(db, "clients", selectedPage.id);
      const clientDocSnap = await getDoc(clientDocRef);

      if (clientDocSnap.exists()) {
        // If the client exists, copy the data to IGclients
        const igClientDocRef = doc(db, "IGclients", selectedPage.igPageId);
        await setDoc(igClientDocRef, { ...clientDocSnap.data(), linkedFacebookPageId: selectedPage.id });

        console.log("Client data copied to IGclients collection.");
        window.location.reload();  // Refresh the page
      } else {
        // If the client does not exist, navigate based on plan
        const planPath = userData.selectedPlan === "Premium" ? "/premium" : "/customize";
        navigate(`${planPath}?pageId=${encodeURIComponent(selectedPage.igPageId)}&platform=instagram`);
      }
    } else {
      console.error("User document not found");
      navigate(`/`);
    }
};
  
  
  


 const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const handleModalOpen = () => {
    setModalOpen(true);
  };

  const handleModalClose = () => {
    setModalOpen(false);
    setActiveStep(0);
  };

  const handlePageSelectionChange = (event) => {
    setSelectedPageId(event.target.value);
  };


  const getStepContent = (stepIndex) => {
    switch (stepIndex) {
      case 0:
        return <Button onClick={handleFacebookLogin}>Connect to Facebook</Button>;
      case 1:
        return (
          <RadioGroup
            name="pageSelection"
            value={selectedPageId}
            onChange={(event) => {
              const selected = pages.find(page => page.id === event.target.value);
              if (!selected.igPageId) {
                alert("This page does not have a linked Instagram account.");
              } else {
                setSelectedPageId(event.target.value);
              }
            }}
          >
            {pages.map(page => (
              <FormControlLabel key={page.id} value={page.id} control={<Radio />} label={`${page.name} (Instagram ID: ${page.igPageId})`} />
            ))}
          </RadioGroup>
        );
      case 2:
        return <Typography>Customize your chatbot settings here.</Typography>;
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  return (
    <React.Fragment>
      <Button variant="contained" onClick={handleModalOpen} sx={{ mb: 2 }}>Create New Chatbot</Button>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Client Name</TableCell>
            <TableCell>Active</TableCell>
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {clientData.map((client, index) => (
            <TableRow key={index}>
              <TableCell>{client.additionalInfo.businessName}</TableCell>
              <TableCell>
                <Switch checked={client.additionalInfo.isActive} onChange={() => toggleClientActive(client.id, client.additionalInfo.isActive)} />
              </TableCell>
              <TableCell>
              <Button component={RouterLink} to={`/edit/${encodeURIComponent(client.id)}?platform=instagram`} variant="contained">Edit</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <Modal open={modalOpen} onClose={handleModalClose}>
        <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', border: '2px solid #000', boxShadow: 24, p: 4 }}>
          <Stepper activeStep={activeStep}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <div>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography>All steps completed - your chatbot is ready!</Typography>
                <Button onClick={handleModalClose}>Close</Button>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep)}
                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                  <Button disabled={activeStep === 0} onClick={handleBack}>Back</Button>
                  <Box sx={{ flex: '1 1 auto' }} />
                  <Button variant="contained" onClick={handleNext}>{activeStep === steps.length - 1 ? 'Finish' : 'Next'}</Button>
                </Box>
              </React.Fragment>
            )}
          </div>
        </Box>
      </Modal>
    </React.Fragment>
  );
};

export default Settings;