import React from 'react';
import './Footer.scss';
import logo from '../../images/logo.png';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div className="footer">
      <div className="footer-row">
        <div className="footer-column">
          <img src={logo} alt="Logo" />
        </div>
        <div className="footer-column">
          <h4 className="footer-title">Company</h4>
          
          <Link to="/about-us">About</Link>
          <a href="#">Careers</a>
          <Link to="/privacy-policy">Privacy & Security</Link>
        </div>
        <div className="footer-column">
          <h4 className="footer-title">Product</h4>
          <a href="#">Messenger</a>
          <a href="#">Instagram</a>
        </div>
        <div className="footer-column">
          <h4 className="footer-title">Support</h4>
          <a href="#">Video Tutorials</a>
          <a href="#">How To</a>
          <a href="#">Blog</a>
        </div>
      </div>
      <div className="footer-row">
        {/* Updated to use Link */}
        <Link className='links' to="/about-us">©A6 INFORMATION TECHNOLOGY CO. L.L.C</Link>
        <Link className='links' to="/privacy-policy">Privacy Policy</Link>
        <Link className='links' to="/terms">Terms of Service</Link>
      </div>
    </div>
  );
};

export default Footer;
