import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithRedirect, createUserWithEmailAndPassword, onAuthStateChanged, getRedirectResult } from "firebase/auth";
import { auth, db } from '../../backend/firebase'; 
import Navbar from '../../components/Navbar/Navbar'; 

import { doc, setDoc,getDoc } from "firebase/firestore"; 

import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import NotificationCard from '../../components/NotificationCard/NotificationCard'; 


const defaultTheme = createTheme();

export default function SignUp() {
  const navigate = useNavigate();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
const [showNotification, setShowNotification] = useState(false);


  useEffect(() => {
    getRedirectResult(auth)
      .then((result) => {
        if (result?.user) {
          const user = result.user;

          if (result._tokenResponse?.isNewUser) {
            setDoc(doc(db, "users", user.uid), {
              hasPaid: false,
              isNewUser: true,
              isConnectedToFacebook: false,
              hasCreatedChatbot: false,
              createdAt: new Date()
            }).then(() => {
              navigate('/payment');
            });
          } else {
            navigate('/payment');
          }
        }
      }).catch((error) => {
        console.error("Error fetching redirect result:", error);
      });

      const unsubscribe = onAuthStateChanged(auth, async (user) => {
        if (user) {
          // Fetch user data from Firestore
          const userDocRef = doc(db, "users", user.uid);
          const docSnap = await getDoc(userDocRef);
  
          if (docSnap.exists()) {
            const userData = docSnap.data();
  
            // Redirect based on user attributes
            if (!userData.hasPaid) {
              navigate('/payment');
            } else if (!userData.isConnectedToFacebook) {
              navigate('/connect-facebook');
            } else if (!userData.hasCreatedChatbot) {
              navigate('/customize');
            } else {
              navigate('/dashboard');
            }
          } else {
            // Handle case where user data does not exist in Firestore
            console.log("No such user data!");
          }
        }
      });

    return () => unsubscribe();
  }, [navigate]);

  const signUpWithGoogle = () => {
    setIsAuthenticating(true);
    const googleProvider = new GoogleAuthProvider();
    signInWithRedirect(auth, googleProvider);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsAuthenticating(true);
    const data = new FormData(event.currentTarget);
  
    createUserWithEmailAndPassword(auth, data.get('email'), data.get('password'))
      .then((userCredential) => {
        // Success: User created, proceed with your logic
        setDoc(doc(db, "users", userCredential.user.uid), {
          hasPaid: false,
          isNewUser: true,
          isConnectedToFacebook: false,
          hasCreatedChatbot: false,
          createdAt: new Date()
        }).then(() => {
          navigate('/payment');
        });
      })
      .catch((error) => {
        setIsAuthenticating(false);
        setShowNotification(true); // Show notification on error
  
        switch (error.code) {
          case 'auth/email-already-in-use':
            setErrorMessage('This email is already in use. Please sign in or use a different email.');
            break;
          // Add more cases as needed
          default:
            setErrorMessage('An error occurred during sign-up. Please try again.');
        }
      });
  };
  

  

  return (
    <ThemeProvider theme={defaultTheme}>
       <Navbar /> 
        {isAuthenticating && (
       <NotificationCard
       type="message"
       message={errorMessage}
       onClose={() => setShowNotification(false)} // Hide notification on close
     />
    )}
      <div
        style={{
          background: 'linear-gradient(to left bottom, #882691, #762283, #651d74, #551866, #451357, #3f0e48, #370b3b, #2f082e, #280821, #200516, #16020b, #000000)',
          minHeight: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Box
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            padding: '20px',
            borderRadius: '16px',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            maxWidth: '400px',
          }}
        >
          <CssBaseline />
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5" style={{ color: 'white' }}>
            Sign up
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 3 }}>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6}>
                <TextField
                  autoComplete="given-name"
                  name="firstName"
                  required
                  fullWidth
                  id="firstName"
                  label="First Name"
                  autoFocus
                  InputProps={{ style: { color: 'white', border: '1px solid white' }, sx: { borderBottomColor: 'white !important' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  fullWidth
                  id="lastName"
                  label="Last Name"
                  name="lastName"
                  autoComplete="family-name"
                  InputProps={{ style: { color: 'white', border: '1px solid white' }, sx: { borderBottomColor: 'white !important' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  id="email"
                  label="Email Address"
                  name="email"
                  autoComplete="email"
                  InputProps={{ style: { color: 'white', border: '1px solid white' }, sx: { borderBottomColor: 'white !important' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  fullWidth
                  name="password"
                  label="Password"
                  type="password"
                  id="password"
                  autoComplete="new-password"
                  InputProps={{ style: { color: 'white', border: '1px solid white' }, sx: { borderBottomColor: 'white !important' } }}
                  InputLabelProps={{ style: { color: 'white' } }}
                />
              </Grid>
            </Grid>
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2, backgroundColor: 'white', color: 'black' }}
            >
              Sign Up
            </Button>
            <Typography variant="body2" align="center" sx={{ mt: 2, color: 'white' }}>
              Or continue with
            </Typography>
            <Button
              fullWidth
              variant="contained"
              onClick={signUpWithGoogle}
              startIcon={<GoogleIcon style={{ color: 'white' }} />}
              sx={{ mt: 2, mb: 1, backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'darkred' } }}
            >
              Google
            </Button>
            <Grid container justifyContent="flex-end">
              <Grid item>
                <RouterLink to="/signin" style={{ textDecoration: 'none' }}>
                  <Typography variant="body2">
                    Already have an account? Sign in
                  </Typography>
                </RouterLink>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </div>
    </ThemeProvider>
  );
}

