import React from 'react';
import { CircularProgress, Card, CardContent, Typography, Button, IconButton, Modal, Box } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const NotificationCard = ({ type, message, actionText, onActionClick, onClose }) => {
  const renderContent = () => {
    switch (type) {
      case 'loading':
        return <CircularProgress />;
      case 'message':
        return (
          <>
            <Typography variant="body1">{message}</Typography>
            {onClose && (
              <IconButton size="small" onClick={onClose}>
                <CloseIcon fontSize="inherit" />
              </IconButton>
            )}
          </>
        );
      case 'actionMessage':
        return (
          <>
            <Typography variant="body1">{message}</Typography>
            {actionText && (
              <Button size="small" onClick={onActionClick}>
                {actionText}
              </Button>
            )}
          </>
        );
      default:
        return <Typography variant="body1">Unknown type</Typography>;
    }
  };

  return (
    <Modal
      open={true}
      onClose={onClose}
      aria-labelledby="notification-modal-title"
      aria-describedby="notification-modal-description"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box sx={{ outline: 'none' }}>
        <Card variant="outlined">
          <CardContent>
            {renderContent()}
          </CardContent>
        </Card>
      </Box>
    </Modal>
  );
};

export default NotificationCard;
