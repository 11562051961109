import React, { useState, useEffect } from 'react';
import { db } from '../../backend/firebase';
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  doc,
  updateDoc,
  arrayUnion,
  serverTimestamp
} from 'firebase/firestore';

import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField
} from '@mui/material';
import Title from '../Title/Title';

export default function Tickets() {
  const [tickets, setTickets] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedTicket, setSelectedTicket] = useState(null);
  const [newMessage, setNewMessage] = useState('');

  useEffect(() => {
const q = query(collection(db, "tickets"));
    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      console.log("Fetched documents:", querySnapshot.docs); // Log fetched documents
      const ticketsArray = querySnapshot.docs.map(doc => {
        console.log("Processing document:", doc.id, doc.data()); // Log each document's data
        return {
          id: doc.id,
          ...doc.data(),
          date: doc.data().date?.toDate().toLocaleString() || 'No Date',
        };
      });
      console.log("Processed tickets array:", ticketsArray); // Log the processed tickets array
      setTickets(ticketsArray);
    }, err => {
      console.error("Error fetching tickets:", err); // Log any errors
    });
  
    return () => unsubscribe();
  }, []);
  

  const handleClickOpen = (ticket) => {
    setSelectedTicket(ticket);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setNewMessage('');
  };

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;
  
    const ticketRef = doc(db, "tickets", selectedTicket.id);
    await updateDoc(ticketRef, {
      messages: arrayUnion({
        message: newMessage,
        sender: 'admin',
        timestamp: new Date(), // Use a client-side generated timestamp
      })
    });
  
    setNewMessage(''); // Clear the message input field
  };
  
  

  return (
    <React.Fragment>
      <Title>All Tickets</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Date</TableCell>
            <TableCell>Subject</TableCell>
            <TableCell>User ID</TableCell>
            <TableCell>Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tickets.map((ticket) => (
            <TableRow key={ticket.id} hover onClick={() => handleClickOpen(ticket)} style={{ cursor: 'pointer' }}>
              <TableCell>{ticket.date}</TableCell>
              <TableCell>{ticket.subject}</TableCell>
              <TableCell>{ticket.uid}</TableCell> {/* User ID added for admin to identify the ticket owner */}
              <TableCell>{ticket.status}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {selectedTicket && (
  <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
    <DialogContent>
      <Title>Subject: {selectedTicket.subject}</Title>
      {/* Display the initial message */}
      {selectedTicket.message1 && (
        <p><b>User:</b> {selectedTicket.message1}</p>
      )}
      {/* Check and display subsequent messages if they exist */}
      {selectedTicket.messages?.length > 0 && (
        selectedTicket.messages.map((msg, index) => (
          <p key={index}><b>{msg.sender}:</b> {msg.message}</p>
        ))
      )}
      <TextField
        margin="dense"
        id="newMessage"
        label="New Message"
        type="text"
        fullWidth
        variant="outlined"
        value={newMessage}
        onChange={(e) => setNewMessage(e.target.value)}
        multiline
        rows={2}
      />
    </DialogContent>
    <DialogActions>
      <Button onClick={handleClose}>Close</Button>
      <Button onClick={handleSendMessage} color="primary">Send</Button>
    </DialogActions>
  </Dialog>
)}

    </React.Fragment>
  );
}

