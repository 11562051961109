import React from 'react';
import './AboutUs.scss';
import trainingImage from '../../../images/chatbot1.png'; // Importing the image

function AboutUs() {
    return (
        <div className="aboutUs">
            <h2>About Us</h2>
            <div className="content">
                <div className="leftSection">
                    <h1>Unleash the Power of Conversation with a6ai: Your Bot-Building Blueprint
                    </h1>
                    <p>Create a fully customized chatbot easily and connect it to Facebook, Instagram, and more.</p>
                    <button className="btn get-started">Get Started</button>
                    <button className="btn main ">Main Page</button>
                </div>
                <div className="rightSection">
                    <img src={trainingImage} alt="A6ai Chatbot" />
                </div>
            </div>
            <div className="secondView">
                <p>Craving the power of automated assistants without the coding headaches? Welcome to a6ai, the innovative platform where anyone, tech-savvy or not, can create their own bespoke chatbots for Facebook and Instagram. We believe conversational interactions are the future, and a6ai empowers you to unlock that potential effortlessly.

                    Imagine crafting a personalized AI companion that engages your audience, answers questions, and drives conversions - all without writing a single line of code. With a6ai's intuitive drag-and-drop interface, building your dream bot is as simple as assembling building blocks. Choose from ready-made conversational components, customize responses, and tailor the experience to perfectly match your brand and goals.

                    Connect your a6ai-powered bot to your Facebook or Instagram pages and watch the magic unfold. Automate greetings, answer FAQs, manage bookings, and nurture leads - all while you focus on what you do best. Engage your followers in real-time, deliver exceptional customer service, and boost your reach with automated interactions that never sleep.

                    a6ai isn't just a platform, it's a revolution in digital communication. We're democratizing bot technology, making it accessible to everyone. Whether you're a small business owner, a marketing maestro, or a creative genius, a6ai puts the power of AI-powered conversations right at your fingertips.

                    Join the a6ai community and start building bots that spark conversations, ignite connections, and transform the way you interact with your audience. The future of communication is here, and it's delightfully conversational.

                    a6ai: Conversation Made Easy. Build. Automate. Engage.</p>
            </div>
        </div>
    );
}

export default AboutUs;
