import * as React from 'react';
import { useState } from 'react';
import Tickets from '../../components/Tickets/Tickets';
import PremiumUsers from '../../components/premiumUsers/premiumUsers'; 
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Badge from '@mui/material/Badge';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Link from '@mui/material/Link';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import NotificationsIcon from '@mui/icons-material/Notifications';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import { mainListItems, secondaryListItems } from '../../components/listItems/listItems';
import Chart from '../../components/Charts/Chart';
import { Deposits, TotalEarnings, TotalExpenses } from '../../components/Financial/Financial';
import Orders from '../../components/Orders/Orders';
import GoogleAnalytics from '../../components/Analytics/GoogleAnalytics';
import TokenGenerator from '../../components/TokenGenerator/TokenGenerator'; 
import VpnKeyIcon from '@mui/icons-material/VpnKey'; // Importing an icon for the token tab


function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://a6ai.com/">
        A6AI
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  backgroundColor: '#004aba',
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,

  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

export default function Dashboard() {
  const [open, setOpen] = useState(true);
  const [selectedSection, setSelectedSection] = useState('Financial'); // Default section

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const handleMenuItemClick = (section) => {
    if (section === 'Analytics') {
      // Open a new window with the specified URL when Analytics is clicked
      window.open("https://analytics.google.com/analytics/web/#/p428897541/reports/reportinghub?params=_u..nav%3Dmaui", "_blank");
    } else {
      setSelectedSection(section);
    }
  };

  const renderSectionContent = () => {
    switch (selectedSection) {
      case 'Financial':
        return (
          <Grid container spacing={3}>
            {/* Chart Component as a Graph */}
            <Grid item xs={12} md={8} lg={9}>
              <Paper
                sx={{
                  p: 2,
                  display: 'flex',
                  flexDirection: 'column',
                  height: 240,
                }}
              >
                <Chart />
              </Paper>
            </Grid>






            {/* Deposits/Revenue */}
            <Grid item xs={12} md={4} lg={3}>
              <Paper elevation={3} sx={{ padding: 2, marginLeft: '10px' }}>
                <Deposits />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ padding: 2, marginLeft: '10px' }}>
                <TotalEarnings />
              </Paper>
            </Grid>

            <Grid item xs={12} md={4}>
              <Paper elevation={3} sx={{ padding: 2, marginLeft: '10px' }}>
                <TotalExpenses />
              </Paper>
            </Grid>


          </Grid>
        );

      case 'Clients':
        return (
          <Grid container spacing={3}>
            {/* Orders Component for Clients */}
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Orders /> 
              </Paper>
            </Grid>
          </Grid>
        );


      case 'Analytics':
        return (
          <Grid container spacing={3}>
            {/* Component to display Google Analytics data */}
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <GoogleAnalytics /> 
              </Paper>
            </Grid>
          </Grid>
        );

      case 'Tickets':
        return (
          <Grid container spacing={3}>
            {/* Ticket Component */}
            <Grid item xs={12}>
              <Paper elevation={3} sx={{ padding: 2 }}>
                <Tickets /> 
              </Paper>
            </Grid>
          </Grid>
        );

        case 'TokenGenerator':
          return <TokenGenerator />;

          case 'PremiumUser':
            return (
              <div>
                 <PremiumUsers /> 
              </div>
            );

      default:
        return <div>Select a section from the menu</div>;
    }
  };



  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          {/* AppBar content */}
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar>
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            {React.cloneElement(mainListItems({ handleMenuItemClick }), {})}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <Grid container spacing={3}>
              {/* Render content based on the selected section */}
              {renderSectionContent()}
            </Grid>
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}