import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { db } from '../../backend/firebase'; 
import { collection, getDocs, query, where, updateDoc, doc } from 'firebase/firestore';
import { Table, TableBody, TableCell, TableHead, TableRow, Button, Switch } from '@mui/material';
import Title from '../Title/Title';

function createData(id, clientName, hasPaid, isActive, activeTill, editLink) {
  return { id, clientName, hasPaid, isActive, activeTill, editLink };
}

export default function Orders() {
  const [rows, setRows] = useState([]);

  useEffect(() => {
    fetchClientsAndUsers();
  }, []);

  const fetchClientsAndUsers = async () => {
    const clientsQuery = query(collection(db, "clients")); 
    const usersQuery = collection(db, "users");

    const clientsSnapshot = await getDocs(clientsQuery);
    const usersSnapshot = await getDocs(usersQuery);

    const clientsData = clientsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    const usersData = usersSnapshot.docs.reduce((acc, doc) => ({ ...acc, [doc.id]: doc.data() }), {});

    const combinedData = clientsData.map(client => {
      const user = usersData[client.additionalInfo.UID];
      const isActive = client.additionalInfo.isActive || false;
      const hasPaid = user?.hasPaid || false;
      // Convert to Date only if user.payedTime exists
      const activeTill = user?.payedTime ? new Date(user.payedTime.seconds * 1000).toLocaleDateString() : 'N/A';
      return createData(client.id, client.additionalInfo.businessName, hasPaid, isActive, activeTill, `/edit/${client.id}`);
    });
    

    setRows(combinedData);
  };

  const toggleChatbotActive = async (clientId, isActive) => {
    const docRef = doc(db, 'clients', clientId);
    await updateDoc(docRef, { 'additionalInfo.isActive': !isActive });
    fetchClientsAndUsers(); // Refetch data to reflect the change
  };

  return (
    <React.Fragment>
      <Title>Recent Customers</Title>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>Client Name</TableCell>
            <TableCell>Has Paid</TableCell>
            <TableCell>Chatbot Active</TableCell>
            <TableCell>Active Till</TableCell>
            <TableCell>Edit</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell>{row.clientName}</TableCell>
              <TableCell>{row.hasPaid ? '✅' : '❌'}</TableCell>
              <TableCell>
                <Switch
                  checked={row.isActive}
                  onChange={() => toggleChatbotActive(row.id, row.isActive)}
                />
              </TableCell>
              <TableCell>{row.activeTill}</TableCell>
              <TableCell>
                <Button component={RouterLink} to={row.editLink} variant="contained">Edit</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
}
