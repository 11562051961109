import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { db } from '../../backend/firebase'; 

import PricingPlan from '../../components/PricingPlan/PricingPlan';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import VisaImage from '../../images/visa.png';
import CreditCardImage from '../../images/credit-card.png';
import MasterCardImage from '../../images/mastercard.png';
import Checkout from './checkout/Checkout';
import './Payment.scss';

import { auth } from '../../backend/firebase'; 


const PaymentPage = () => {
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [promoCode, setPromoCode] = useState('');
  const navigate = useNavigate();
  const currentUserUID = auth.currentUser ? auth.currentUser.uid : null;


  const plans = [
    { name: "Pro", price: "$500/month", features: ["Self Create", "Self Manage"], stripeUrl: "https://buy.stripe.com/test_3cs9E19uk6s26Ji6op?client_reference_id=${userId}" },
    { name: "Premium", price: "$750/month", features: ["Technical Help", "Talk to team"], stripeUrl: "https://buy.stripe.com/test_3cs9E19uk6s26Ji6op?client_reference_id=${userId}" }
  ];

  const handlePlanSelect = (planName) => {
    setSelectedPlan(planName);
  };

  const handlePaymentMethodSelect = (method) => {
    setSelectedPaymentMethod(method);
  };

  const getStripeUrl = () => {
    const plan = plans.find(p => p.name === selectedPlan);
    return plan ? plan.stripeUrl : '';
  };
  const handleApplyPromoCode = async () => {
    const currentUserUID = auth.currentUser ? auth.currentUser.uid : null;
    const tokenRef = doc(db, "tokens", promoCode);
    const tokenSnap = await getDoc(tokenRef);
  
    if (tokenSnap.exists() && currentUserUID) {
      const tokenData = tokenSnap.data();
      const now = new Date();
  
      if (tokenData.isValid && tokenData.expiresAt.toDate() > now) {
        await updateDoc(tokenRef, { isValid: false }); // Mark the token as used
  
        // Update the user's document in the Firestore with the selected plan
        const userRef = doc(db, "users", currentUserUID);
        await updateDoc(userRef, {
          hasPaid: true,
          payedTime: now,
          selectedPlan: selectedPlan // Save the selected plan
        });
  
        alert("Promo code applied successfully! Redirecting to connect to Facebook...");
        navigate('/connect-facebook');
      } else {
        alert("Invalid or expired promo code.");
      }
    } else {
      alert("Invalid promo code or user not found.");
    }
  };
  
   return (
    <div className="payment-page">
      <div className="plan-selection">
        <h2>Please choose your plan</h2>
        <div className="plans">
          {plans.map((plan, index) => (
            <PricingPlan
              key={index}
              planName={plan.name}
              price={plan.price}
              features={plan.features}
              isSelected={selectedPlan === plan.name}
              onClick={() => handlePlanSelect(plan.name)}
            />
          ))}
        </div>
      </div>
      <div className="payment-method-selection">
        <h2>Please choose your payment method</h2>
        <div className="payment-options">
          <div
            className={`payment-option ${selectedPaymentMethod === 'PromoCode' ? 'selected' : ''}`}
            onClick={() => handlePaymentMethodSelect('PromoCode')}
          >
            <LocalOfferIcon />
          </div>
          <div
            className={`payment-option ${selectedPaymentMethod === 'CreditCard' ? 'selected' : ''}`}
            onClick={() => handlePaymentMethodSelect('CreditCard')}
          >
            <img src={VisaImage} alt="Visa" />
            <img src={CreditCardImage} alt="Credit Card" />
            <img src={MasterCardImage} alt="MasterCard" />
          </div>
        </div>
      </div>
      {selectedPaymentMethod === 'CreditCard' && <Checkout stripeUrl={getStripeUrl()} />}
      {selectedPaymentMethod === 'PromoCode' && (
        <div className="promo-code-container">
          <input
            type="text"
            value={promoCode}
            onChange={(e) => setPromoCode(e.target.value)}
            placeholder="Enter Promo Code"
            className="promo-code-input"
          />
          <button onClick={handleApplyPromoCode} className="apply-btn">Apply</button>
        </div>
      )}
    </div>
  );
};

export default PaymentPage;