import React, { useState, useEffect, useRef } from 'react';
import './ChatbotPhone.scss';
import AccountCircleRoundedIcon from '@mui/icons-material/AccountCircleRounded';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import VideoCallIcon from '@mui/icons-material/VideoCall';
import InfoIcon from '@mui/icons-material/Info';
import { db } from '../../backend/firebase';
import { doc, getDoc } from "firebase/firestore";

const ChatbotPhone = ({ docId = "0000000000" }) => {
    const [messages, setMessages] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [mainMenuOptions, setMainMenuOptions] = useState([]);
    const [data, setData] = useState({ subOptions: {}, subSubOptions: {}, subSubOptionDetails: {} });


    const messagesEndRef = useRef(null);

    useEffect(() => {
        fetchInitialOptions();
    }, []);

    useEffect(() => {
        setTimeout(() => {
            messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        }, 100); // Adjust delay as necessary
    }, [messages]);


    const handleInputChange = (event) => {
        if (event.key === 'Enter') {
            handleSendMessage(inputValue);
        } else {
            setInputValue(event.target.value);
        }
    };

    const handleSendMessage = async (text = inputValue, isQuickReply = false) => {
        if (text.trim() === '' && !isQuickReply) return;

        // Clear the input field if it's not a quick reply
        if (!isQuickReply) {
            addMessage('user', text, 'normal');
            setInputValue('');
        }

        // Check for greeting messages
        const greetings = ['hi', 'hello', 'hey'];
        if (greetings.includes(text.toLowerCase())) {
            const greetingResponse = "Hello! How can I help you today?";
            addMessage('bot', greetingResponse, 'normal', mainMenuOptions);
            return;
        }

        // Main Menu handling
        if (text === "Main Menu") {
            addMessage('bot', "How can I assist you today?", 'normal', mainMenuOptions);
            return;
        }

        // Check if the text matches any carousel sub-options
        const carouselSubOptions = identifyCarouselSubOptions(data.subOptions, data.subSubOptions, data.subSubOptionDetails);
        if (carouselSubOptions.includes(text)) {
            const subSubChildren = data.subSubOptions[text] || [];
            const templateMessages = subSubChildren
                .filter(child => data.subSubOptionDetails[child] && data.subSubOptionDetails[child].type === 'template')
                .map(child => {
                    const templateDetail = data.subSubOptionDetails[child];
                    const templateMessage = {
                        title: templateDetail.title,
                        description: templateDetail.description,
                        imageUrl: templateDetail.imageUrl,
                        buttons: [{
                            text: templateDetail.button1Title,
                            value: templateDetail.button1URL
                        }]
                    };
                    // Check if the second button exists and add it to the message
                    if (templateDetail.button2Title && templateDetail.button2URL) {
                        templateMessage.buttons.push({
                            text: templateDetail.button2Title,
                            value: templateDetail.button2URL
                        });
                    }
                    return templateMessage;
                });

            // Use 'carousel' as messageType for grouped template messages
            addMessage('bot', templateMessages, 'carousel');
            return;
        }



        // If the input doesn't match any special handling, search Firestore for further action
        await searchInFirestore(text);
    };


    // Modified addMessage function to handle different message types
    // Modified addMessage to handle an array of templates
    const addMessage = (sender, content, messageType, quickReplies = []) => {
        if (messageType === 'carousel') {
            // Bundle all templates into one message for carousel
            const message = { sender, messageType, content, quickReplies: [...quickReplies, ...(messages.length > 0 ? ["Main Menu"] : [])] };
            setMessages(messages => [...messages, message]);
        } else {
            let messagesToAdd = Array.isArray(content) ? content : [content];
            messagesToAdd.forEach(msg => {
                const message = { sender, messageType, quickReplies: [...quickReplies, ...(messages.length > 0 ? ["Main Menu"] : [])] };
                if (messageType === 'template') {
                    // If it's a template type, use structured message content
                    message.content = msg;
                } else {
                    // For normal and button messages, use text field
                    message.text = msg;
                }
                setMessages(messages => [...messages, message]);
            });
        }
    };



    function identifyCarouselSubOptions() {
        const carouselSubOptions = [];
        for (const [option, subOptionList] of Object.entries(data.subOptions)) {
            for (const subOption of subOptionList) {
                const children = data.subSubOptions[subOption] || [];
                if (children.length > 1) {
                    let hasTemplateChild = children.some(child => data.subSubOptionDetails[child] && data.subSubOptionDetails[child].type === 'template');
                    if (hasTemplateChild) {
                        carouselSubOptions.push(subOption);
                    }
                }
            }
        }
        return carouselSubOptions;
    }


    // Integration of this function inside your fetchInitialOptions function
    const fetchInitialOptions = async () => {
        const docRef = doc(db, "clients", docId);
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
            const fetchedData = docSnap.data();
            setData(fetchedData); // Store the fetched data in state
            setMainMenuOptions(fetchedData.options || []);
        } else {
            console.error("Document with ID:", docId, "does not exist!");
        }
    };




    const searchInFirestore = async (query) => {
        const documentId = docId; 
        try {
            const docRef = doc(db, "clients", documentId);
            const docSnap = await getDoc(docRef);

            if (!docSnap.exists()) {
                console.error("Document not found with ID:", documentId);
                addMessage('bot', "Sorry, I couldn't find any relevant information.", 'normal', mainMenuOptions);
                return;
            }

            const data = docSnap.data();
            const queryLower = query.toLowerCase().trim();

            // Function to process options in a case-insensitive and nested manner
            const processOptions = (optionsDict) => {
                for (const [key, values] of Object.entries(optionsDict)) {
                    const cleanedKey = key.toLowerCase().trim();
                    if (queryLower === cleanedKey) {
                        if (typeof values === 'object' && !Array.isArray(values) && values !== null) {
                            // Handle nested dictionary structures
                            return values;
                        } else if (Array.isArray(values) && values.some(val => val.toLowerCase().trim() !== cleanedKey)) {
                            // Ensure array values are not just the key repeated
                            return values;
                        }
                    }
                }
                return null; // Return null if no match is found
            };

            // Search through different option levels
            let result = processOptions(data.subOptions || {});
            if (result !== null) {
                addMessage('bot', `Options for ${query}:`, 'normal', result);
                return;
            }

            result = processOptions(data.subSubOptions || {});
            if (result !== null) {
                addMessage('bot', `More details for ${query}:`, 'normal', result);
                return;
            }

            result = processOptions(data.subSubOptionDetails || {});
            if (result !== null) {
                handleDetailResponse(result);
                return;
            }

            // If no match found
            addMessage('bot', "Sorry, I couldn't find any information related to your query.", 'normal', mainMenuOptions);
        } catch (error) {
            console.error("Error searching in Firestore:", error);
            addMessage('bot', "There was an error processing your request.", 'normal', mainMenuOptions);
        }
    };


    const handleDetailResponse = (detail) => {
        switch (detail.type) {
            case 'normal':
                // Handle normal text message
                addMessage('bot', detail.message, 'normal');
                break;

            case 'button':
                const buttons = [];
                // Check and add the first button if it exists
                if (detail.buttonText && detail.buttonValue) {
                    buttons.push({ text: detail.buttonText, value: detail.buttonValue });
                }
                // Check and add the second button if it exists
                if (detail.button2Text && detail.button2Value) {
                    buttons.push({ text: detail.button2Text, value: detail.button2Value });
                }

                // Only add the button message if there's at least one button
                if (buttons.length > 0) {
                    addMessage('bot', { buttons: buttons }, 'button');
                } else {
                    // Send an error message if no valid buttons were configured
                    addMessage('bot', "Sorry, there was an issue with the button configuration.", 'normal');
                }
                break;

            case 'template':
                const templateMessageContent = {
                    title: detail.title,
                    description: detail.description,
                    imageUrl: detail.imageUrl, 
                    button1Title: detail.button1Title,
                    button1URL: detail.button1URL,
                };
                if (detail.button2Title && detail.button2URL) {
                    templateMessageContent.button2Title = detail.button2Title;
                    templateMessageContent.button2URL = detail.button2URL;
                }
                addMessage('bot', templateMessageContent, 'template');
                break;

            default:
                // Handle unknown detail type
                addMessage('bot', "Sorry, I encountered an unknown message type.", 'normal');
        }
    };


    const renderMessageContent = (message, index) => {
        return (
            <React.Fragment key={index}>
                {message.messageType === 'normal' && (
                    <div className={`message ${message.sender}`}>
                        <div className="message-text">{message.text}</div>
                    </div>
                )}
                {message.messageType === 'button' && (
                    <div className={`message ${message.sender} button-message`}>
                        {message.content.buttons.map((btn, btnIndex) => (
                            <button
                                key={btnIndex}
                                className="message-button"
                                onClick={() => {
                                    const url = btn.value.startsWith('http://') || btn.value.startsWith('https://')
                                        ? btn.value
                                        : `http://${btn.value}`;
                                    window.open(url, "_blank");
                                }}
                            >
                                {btn.text}
                            </button>
                        ))}
                    </div>
                )}
                {message.messageType === 'template' && (
                    <div className={`message ${message.sender} template-message`}>
                        <div className="template-image" style={{ backgroundImage: `url(${message.content.imageUrl || 'https://via.placeholder.com/150'})` }}></div>
                        <div className="template-content">
                            <div className="template-title">{message.content.title}</div>
                            <div className="template-description">{message.content.description}</div>
                            {message.content.button1Title && (
                                <button
                                    className="template-button"
                                    onClick={() => {
                                        const url1 = message.content.button1URL.startsWith('http://') || message.content.button1URL.startsWith('https://')
                                            ? message.content.button1URL
                                            : `http://${message.content.button1URL}`;
                                        window.open(url1, "_blank");
                                    }}
                                >
                                    {message.content.button1Title}
                                </button>
                            )}
                            {message.content.button2Title && (
                                <button
                                    className="template-button"
                                    onClick={() => {
                                        const url2 = message.content.button2URL.startsWith('http://') || message.content.button2URL.startsWith('https://')
                                            ? message.content.button2URL
                                            : `http://${message.content.button2URL}`;
                                        window.open(url2, "_blank");
                                    }}
                                >
                                    {message.content.button2Title}
                                </button>
                            )}
                        </div>
                    </div>
                )}

                {message.messageType === 'carousel' && (
                    <div className="carousel-container">
                        {message.content.map((template, idx) => (
                            <div key={idx} className={`message ${message.sender} template-message`}>
                                <div className="template-image" style={{ backgroundImage: `url(${template.imageUrl || 'https://via.placeholder.com/150'})` }}></div>
                                <div className="template-content">
                                    <div className="template-title">{template.title}</div>
                                    <div className="template-description">{template.description}</div>
                                    {template.buttons.map((btn, btnIndex) => (
                                        <button key={btnIndex} className="template-button" onClick={() => window.open(btn.value, "_blank")}>
                                            {btn.text}
                                        </button>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                )}

                {message.sender === 'bot' && message.quickReplies.length > 0 && (
                    <div className="quick-replies">
                        {message.quickReplies.map((reply, qrIndex) => (
                            <button key={`qr-${index}-${qrIndex}`} onClick={() => handleSendMessage(reply, true)} className="quick-reply">
                                {reply}
                            </button>
                        ))}
                    </div>
                )}
            </React.Fragment>
        );
    };







    return (
        <div className="iphone-14">
            <div className="iphone-14-screen">
                <div className="chat-top-bar">
                    <ArrowBackIcon />
                    <AccountCircleRoundedIcon />
                    <p>A6 Chatbot</p>
                    <VideoCallIcon />
                    <InfoIcon />
                </div>

                <div className="chat-messages">
                    {messages.map((message, index) => renderMessageContent(message, index))}
                    <div ref={messagesEndRef} /> {/* This empty div acts as a scroll target */}
                </div>



                <div className="chat-input-area">
                    <input
                        type="text"
                        placeholder="Type a message..."
                        value={inputValue}
                        onChange={(e) => setInputValue(e.target.value)}
                        onKeyPress={handleInputChange} // Add this line
                    />
                    <button onClick={() => handleSendMessage()}>Send</button>
                </div>
            </div>
        </div>
    );
};

export default ChatbotPhone;