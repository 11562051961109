import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, TextField, Button, List, ListItem, Box } from '@mui/material';
import { db } from '../../backend/firebase';
import { doc, updateDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';

const TicketDetailsModal = ({ ticket, open, handleClose }) => {
  const [newMessage, setNewMessage] = useState('');
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Combine initial and subsequent messages, ensuring all have the 'message' field
    const combinedMessages = ticket?.messages?.map(msg => ({
      ...msg,
      // Convert timestamp if it's a Firestore Timestamp object, otherwise use it directly
      timestamp: msg.timestamp?.toDate ? msg.timestamp.toDate() : msg.timestamp,
    })) || [];

    // Sort messages by timestamp
    combinedMessages.sort((a, b) => a.timestamp - b.timestamp);

    setMessages(combinedMessages);
  }, [ticket]);

  const handleSendMessage = async () => {
    if (!ticket || !newMessage.trim()) return;

    const messageToAdd = {
      message: newMessage,
      sender: 'user', 
    };

    await updateDoc(doc(db, "tickets", ticket.id), {
      messages: arrayUnion(messageToAdd),
    });

    setNewMessage('');
    setMessages([...messages, { ...messageToAdd, timestamp: new Date() }]); 
  };

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
      <DialogContent>
        <h2>{ticket?.subject}</h2>
        <List style={{ maxHeight: '400px', overflowY: 'auto' }}>
          {messages.map((msg, index) => (
            <ListItem key={index} style={{ display: 'flex', flexDirection: msg.sender === 'user' ? 'row-reverse' : 'row', alignItems: 'flex-start' }}>
              <Box
                sx={{
                  background: msg.sender === 'user' ? 'lightblue' : 'lightgrey',
                  color: '#000',
                  padding: '8px',
                  borderRadius: '4px',
                  maxWidth: '75%',
                  wordBreak: 'break-word',
                }}
              >
                <div>{msg.message}</div>
                <div style={{ fontSize: '0.75rem', marginTop: '4px', color: '#666', textAlign: 'right' }}>
                  {msg.timestamp.toLocaleString()}
                </div>
              </Box>
            </ListItem>
          ))}
        </List>
        <TextField
          variant="outlined"
          fullWidth
          margin="dense"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder="Type your message here..."
          multiline
          rows={2}
          onKeyPress={(e) => e.key === 'Enter' && !e.shiftKey && handleSendMessage()}
        />
        <Button onClick={handleSendMessage} color="primary" style={{ marginTop: '8px' }}>Send</Button>
      </DialogContent>
    </Dialog>
  );
};

export default TicketDetailsModal;


