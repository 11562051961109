import React from 'react';
import './PrivacyPolicy.scss';

function PrivacyPolicy() {
    return (
        <div className="privacyPolicy">
            <h1 className="title">Privacy and Policy</h1>
            <div className="content">
                <div className="rightColumn">
                    <p>Welcome to a6ai! We're excited to have you build amazing chatbots with our platform. This Privacy Policy and Terms of Use ("Policy") outlines how we collect, use, and protect your information, as well as how you can use our services. By using a6ai, you agree to be bound by this Policy.</p>
                    <p>At A6 Information Technology Co. LLC. ("A6," "we," "us"), safeguarding the privacy and security of personal data is of paramount importance.
                        We handle personal data for (1) our internal operations and (2) at the direction of our clients who utilize A6 services ("Services"), including uploading and storing certain information within them. In the latter scenario, we strictly adhere to our clients' instructions and refrain from using the data for any purposes other than facilitating the provision of the Services (for further details, please refer to our Data Processing Addendum).
                        This Privacy Policy outlines how we process personal data pertaining to the following categories of individuals for our internal operations:
                        • Our clients, their end users, and representatives
                        • Visitors of our website
                        • Subscribers to our newsletters
                        • Prospective clients
                        • Our business partners and their representatives
                        It encompasses data collected within the Services, on our website a6ai.ai or other platforms administered by A6 where this Privacy Policy is posted (the "Site"), within our mobile applications, and from other sources.</p>
                    <div>
                        <h2 className="section-small-title">1. How A6 Collects Personal Data</h2>
                        <p><strong>Use of A6 Services:</strong> When individuals use A6 services, we store all provided content, including information relating to them as customers or end users. This data is gathered directly from individuals or through integrations with platforms like Facebook or Instagram.</p>
                        <p><strong>Correspondence:</strong> Emails or messages sent to A6 are stored, including email content, attachments, and contact details.</p>
                        <p><strong>Form Submissions and Events:</strong> Information provided through form submissions on the A6 website or participation in events is collected, including contact details and form responses. Additionally, joint event information may be received from partners.</p>
                        <p><strong>Website Usage:</strong> When individuals use the A6 website, certain information is collected, such as cookie data, which, alone or combined with other information, may constitute personal data.</p>
                        <p><strong>Community Memberships:</strong> Data from individuals' profiles and posted comments in A6-associated groups on platforms like Facebook are accessible, but not stored by A6.</p>
                    </div>
                    <div>
    <h2 className="section-small-title">2. Types of Personal Data Processed by A6</h2>
    <p><strong>Customer Account Details:</strong> Information necessary for creating or updating accounts and providing services, obtained from individuals or third-party integrations.</p>
    <p><strong>Financial Information:</strong> Details required for processing service subscription payments, including credit card information.</p>
    <p><strong>Contact and Business Data:</strong> Information about customers and potential partners for communication and collaboration purposes.</p>
    <p><strong>Correspondence Details:</strong> Messages, support requests, and submitted form data, including content and contact details.</p>
    <p><strong>Usage Data and Technical Information:</strong> Automatically collected metadata and log files when interacting with A6 services, including IP addresses, browser information, and device identifiers.</p>
    <p><strong>Email Performance Data:</strong> Tracking data for engagement and performance metrics of newsletters.</p>
    <p><strong>Website Cookies and Similar Technologies:</strong> Information collected through cookie technology on the A6 website.</p>
    <p><strong>Customer Content:</strong> Personal data imported into A6 systems by customers, collected from their own users, customers, or social media contacts, processed on behalf of the customer.</p>
</div>

<div>
    <h2 className="section-small-title">3. Purposes for Processing Personal Data</h2>
    <p><strong>Service Operations:</strong> To fulfill agreements with customers, operate accounts, communicate regarding service-related matters, and perform billing obligations.</p>
    <p><strong>Service Provision:</strong> Processing customer content information on behalf of customers.</p>
    <p><strong>Communication:</strong> Sending marketing communications, event reminders, and feedback requests related to A6 services, with opt-out options provided.</p>
    <p><strong>Legal Compliance:</strong> Ensuring compliance with applicable laws, responding to legal requests, and meeting contractual obligations.</p>
    <p><strong>Negotiation and Performance:</strong> Collecting and processing information for negotiation, entry, and performance of agreements with partners.</p>
    <p><strong>Compliance and Safety:</strong> Enforcing service terms, protecting rights and safety, and deterring fraudulent or illegal activity.</p>
</div>

<div>
    <h2 className="section-small-title">4. How A6 Shares Personal Data</h2>
    <p>A6 prioritizes the protection of your data and does not sell it to third parties for commercial or advertising purposes. We share your data only as outlined in this Privacy Policy or with your explicit consent.</p>
    <p>We disclose personal data to third parties in the following circumstances:</p>
    <p><strong>Service Providers:</strong> We engage third-party companies and individuals to assist us with certain activities, such as payment processing and data management using third-party software. These service providers are bound by agreements to use your personal data solely for lawful business purposes and in accordance with our instructions.</p>
    <p><strong>Professional Advisors:</strong> Personal data may be disclosed to professional advisors, including lawyers, bankers, auditors, and insurers, as necessary for the professional services they provide to us.</p>
    <p><strong>Third-party Applications and Integrations:</strong> In providing our services, we may receive and share data with integrations and applications linked by customers, such as Facebook, Instagram, Stripe, and Paypal, as well as applications developed through the "A6 Developer Program."</p>
    <p><strong>Business Transfers:</strong> In the course of our business development, we may engage in corporate transactions such as sales, mergers, or acquisitions. In such events, personal data may be transferred as part of the assets. Any successor or acquirer of A6 (or its assets) will continue to adhere to this Privacy Policy. Aggregated personal data may also be disclosed to describe our services to prospective acquirers or business partners.</p>
</div>

<div>
    <h2 className="section-small-title">5. Data Retention</h2>
    <p>At A6, we maintain and process personal data until the termination of the agreement with us, as outlined in our Terms of Service.</p>
    <p>Additionally, certain data may be retained for a period required by law or necessary to resolve disputes, prevent abuse, and enforce our agreements.</p>
</div>

<div>
    <h2 className="section-small-title">6. Data Transfers</h2>
    <p>Your personal data may be stored and processed in any country where we have facilities or engage service providers, including the U.S.</p>
    <p>By accepting the terms of this Privacy Policy, you acknowledge and consent to:</p>
    <ul>
        <li>The transfer and processing of personal data on servers located outside your country of residence.</li>
        <li>Our collection and utilization of your personal data as described herein and in compliance with the data protection laws of the countries where we operate, which may differ and may provide differing levels of protection compared to those in your country.</li>
    </ul>
</div>

<div>
    <h2 className="section-small-title">7. Changes to Our Privacy Policy</h2>
    <p>At A6, we may find it necessary to amend this Privacy Policy periodically to align with legal, technical, or business developments. When such changes occur, we will take appropriate steps to notify you before the revised policy takes effect, in accordance with the significance of the modifications made. We will seek your consent for any substantial changes to the Privacy Policy if required by applicable data protection laws.</p>
    <p>You can determine the latest update to this Privacy Policy by referring to the "EFFECTIVE DATE" displayed at the top. The updated Privacy Policy will be applicable to all current and previous users of the website, superseding any prior notices that may be inconsistent with it. We are committed to keeping you informed and ensuring transparency regarding any alterations to our Privacy Policy.</p>
</div>

                   
                    <h2 className="section-small-title">8. Contact Us:</h2>
                    <p>If you have any questions about this Policy, please contact us at a6@a6ai.ai</p>

                    <p>By using a6ai, you agree to this Privacy Policy and Terms of Use.</p>
                </div>
            </div>
        </div>
    );
}

export default PrivacyPolicy;