import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Home from './Pages/home/Home'; 
import SignIn from './Pages/login/signin';
import SignUp from './Pages/signup/signup';
import CustomizationPage from './Pages/customizationpage/customizationpage'; 
import CustomizationPremium from './Pages/premium/customization'; 

import EditPage from './Pages/editPage/editPage'; 

import AdminDashboard from './Pages/dashboard/Dashboard'; 
import UserDashboard from './Pages/userDashboard/UserDashboard';

import ConnectFacebook from './Pages/ConnectFacebook/ConnectFacebook';
import Connect from './Pages/ConnectFacebook/connect';


import Payment from './Pages/payment/Payment';
import AboutUs from './Pages/other/about_us/AboutUs';
import PrivacyPolicy from './Pages/other/privacy/PrivacyPolicy';
import Demo from './Pages/demo/Demo';
import ReactGA from "react-ga4";

import TermsConditions from './Pages/other/privacy/TermsConditions';
import ProtectedRoute from './hoc/ProtectedRoute'; 
import AdminProtectedRoute from './hoc/AdminProtectedRoute'; 

//this is lastest folder


function usePageViews() {
  let location = useLocation();
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
  }, [location]);
}

function PageTracker() {
  usePageViews();
  return null;
}

function App() {
  ReactGA.initialize("G-NEHB369D22");

  
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/signin" element={<SignIn />} />
        <Route path="/signup" element={<SignUp />} />
        <Route path="/customize" element={<ProtectedRoute><CustomizationPage /></ProtectedRoute>} />
        <Route path="/premium" element={<ProtectedRoute><CustomizationPremium /></ProtectedRoute>} />
        <Route path="/edit/:pageId" element={<ProtectedRoute><EditPage /></ProtectedRoute>} />
        <Route path="/a6ai-admin" element={<AdminProtectedRoute><AdminDashboard /></AdminProtectedRoute>} />
        <Route path="/dashboard" element={<ProtectedRoute><UserDashboard /></ProtectedRoute>} />
        <Route path="/connect-facebook" element={<ProtectedRoute><ConnectFacebook /></ProtectedRoute>} />
        <Route path="/connect" element={<ProtectedRoute><Connect /></ProtectedRoute>} />
        <Route path="/payment" element={<ProtectedRoute><Payment /></ProtectedRoute>} />
        <Route path="/about-us" element={<AboutUs />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        <Route path="/terms" element={<TermsConditions />} />
        <Route path="/demo" element={<Demo />} />
      </Routes>
    </Router>
  );
}

export default App;
