import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { doc, setDoc, getDoc, updateDoc } from 'firebase/firestore';
import { db, auth } from '../../backend/firebase';
import { RadioGroup, FormControlLabel, Radio, Box, Button, Stepper, Step, StepLabel, Typography, Card, CardContent, Modal, Checkbox, Link } from '@mui/material';

const steps = [
  'Connect Your Facebook Page',
  'Select Your Page',
  'Customize Your Chatbot'
];

export default function HorizontalLinearStepper() {
  const navigate = useNavigate();
  const [activeStep, setActiveStep] = useState(0);
  const [pages, setPages] = useState(null);
  const [selectedPageId, setSelectedPageId] = useState('');
  const userId = auth.currentUser?.uid;
  const [privacyModalOpen, setPrivacyModalOpen] = useState(false);
  const [agreeToPrivacy, setAgreeToPrivacy] = useState(false);
  useEffect(() => {
    if (activeStep === 0) {
      window.fbAsyncInit = function () {
        window.FB.init({
          appId: '940575337706229',
          cookie: true,
          xfbml: true,
          version: 'v18.0'
        });

        window.FB.AppEvents.logPageView();
      };

      (function (d, s, id) {
        var js, fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        js = d.createElement(s); js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      }(document, 'script', 'facebook-jssdk'));
    }
  }, [activeStep]);

  const handleFacebookLogin = () => {
    if (agreeToPrivacy) {
      window.FB.login(response => {
        if (response.status === 'connected') {
          fetchPages();
          setActiveStep(1);
          setPrivacyModalOpen(false); // Close privacy modal on successful connection
        } else {
          console.error('User did not successfully connect.');
        }
      }, { scope: 'email,public_profile,pages_show_list,pages_messaging,instagram_basic,instagram_manage_messages' });
    } else {
      alert("You must agree to the privacy policy before proceeding.");
    }
  };

  const openPrivacyModal = () => {
    setPrivacyModalOpen(true);
  };




  const fetchPages = () => {
    window.FB.api('/me/accounts', response => {
      if (response && response.data) {
        setPages(response.data); // Store the pages with their Page Access Tokens

        // Console log each page's name and its access token
        response.data.forEach(page => {
          console.log(`Page Name: ${page.name}, Page Access Token: ${page.access_token}`);
        });

      } else {
        console.error('Error fetching pages:', response);
      }
    });
  };



  const exchangeTokenForLongLived = async (shortLivedToken) => {
    const appId = '940575337706229';
    const appSecret = '3d93f81ede8975c85a29546050bd5fbb';
    try {
      const response = await fetch(`https://graph.facebook.com/oauth/access_token?grant_type=fb_exchange_token&client_id=${appId}&client_secret=${appSecret}&fb_exchange_token=${shortLivedToken}`);
      const data = await response.json();
      return data.access_token; // Returns the long-lived token
    } catch (error) {
      console.error("Error exchanging token:", error);
      return null; // Handle error appropriately
    }
  };
  const handleNext = async () => {
    if (activeStep === 1 && (!selectedPageId || !pages || pages.length === 0)) {
      alert("Please connect and select a page before proceeding.");
      return; // Stop the function from proceeding
    }

    if (activeStep === 1) {
      const selectedPage = pages.find(page => page.id === selectedPageId);
      if (selectedPage) {

        const longLivedToken = await exchangeTokenForLongLived(selectedPage.access_token);
        if (!longLivedToken) {
          alert("Failed to obtain a long-lived token.");
          return;
        }
        const userDocRef = doc(db, 'users', userId);
        const userDocSnap = await getDoc(userDocRef);

        let newPageData = {
          id: selectedPage.id,
          name: selectedPage.name,
          accessToken: longLivedToken // Store the long-lived token
        };

        // Check if the document exists and update it
        if (userDocSnap.exists() && userDocSnap.data().pageIds) {
          let pageIds = userDocSnap.data().pageIds;
          if (!pageIds.some(page => page.id === selectedPage.id)) { // Check to avoid duplicates
            pageIds.push(newPageData);
          }
          await updateDoc(userDocRef, {
            isConnectedToFacebook: true,
            pageIds: pageIds
          });
        } else {
          // If pageIds does not exist, initialize it
          await setDoc(userDocRef, {
            isConnectedToFacebook: true,
            pageIds: [newPageData]
          }, { merge: true });
        }

        console.log(`Page details saved successfully for ${selectedPage.name}`);
      }
    }

    if (activeStep === steps.length - 1) {
      // Fetch user data from Firestore to check the selectedPlan
      const userDocRef = doc(db, "users", userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
        const userData = userDocSnap.data();
        // Check the selectedPlan and navigate accordingly
        if (userData.selectedPlan === "Premium") {
          navigate(`/premium?pageId=${encodeURIComponent(selectedPageId)}`);
        } else {
          navigate(`/customize?pageId=${encodeURIComponent(selectedPageId)}`);
        }
      } else {
        console.error("User document not found");
        // Handle case when user document is not found, maybe navigate to a default route
        navigate(`/`);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
    }
  };




  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
    setPages(null);
    setSelectedPageId('');
  };

  const handlePageSelectionChange = (event) => {
    setSelectedPageId(event.target.value);
  };

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <div>
            <Typography variant="h6" gutterBottom>
              Review what A6AI is requesting permission to do:
            </Typography>
            <Typography variant="body2" gutterBottom>
              <ul>
                <li>Receive your email address - To associate your chatbot with your email.</li>
                <li>Access profile and posts from the Instagram account connected to your Page - To integrate social features into your chatbot.</li>
                <li>Manage and access Page conversations in Messenger - Allows your chatbot to send and receive messages on your behalf.</li>
                <li>Manage and access messages for the Instagram account connected to your Page - To automate responses and interact with your followers.</li>
                <li>Manage accounts, settings, and webhooks for a Page - To ensure the chatbot can operate under your page settings securely.</li>
                <li>Show a list of the Pages you manage - To let you choose which page to connect the chatbot to.</li>
              </ul>
            </Typography>
            <Button onClick={openPrivacyModal} variant="contained" color="primary">
              Connect with Facebook
            </Button>
          </div>
        );
      case 1:
        return (
          <RadioGroup
            name="pageSelection"
            value={selectedPageId}
            onChange={handlePageSelectionChange}
          >
            {pages && pages.map(page => (
              <FormControlLabel
                key={page.id}
                value={page.id}
                control={<Radio />}
                label={page.name}
              />
            ))}
          </RadioGroup>
        );
      case 2:
        return "Customize your chatbot settings here.";
      default:
        return "Unknown step";
    }
  };

  return (
    <Box sx={{ width: '100%', height: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center', background: 'linear-gradient(to top, #101869, #211a6c, #2e1d6f, #392072, #432375, #40226e, #3e2167, #3b2060, #2d1a4e, #21153c, #160d2a, #0b001a)' }}>
      <Card sx={{ minWidth: 275, borderRadius: '16px', background: 'white' }}>
        <CardContent>
          <Stepper activeStep={activeStep} sx={{ pt: 3, pb: 5 }}>
            {steps.map((label, index) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
          {activeStep === steps.length ? (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>
                All steps completed - you&apos;re finished
              </Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2, justifyContent: 'center' }}>
                <Button onClick={handleReset}>Reset</Button>
              </Box>
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Typography sx={{ mt: 2, mb: 1 }}>{getStepContent(activeStep)}</Typography>
              <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  sx={{ mr: 1 }}
                >
                  Back
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button variant="contained" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? 'Finish' : 'Next'}
                </Button>
                <Modal open={privacyModalOpen} onClose={() => setPrivacyModalOpen(false)}>
                  <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', p: 4, border: '2px solid #000' }}>
                    <Typography>Please review and agree to our privacy policy before proceeding:</Typography>
                    <Link href="https://a6ai.ai/privacy-policy" target="_blank" rel="noopener noreferrer">
                      Read our Privacy Policy
                    </Link>
                    <br />

                    <Checkbox checked={agreeToPrivacy} onChange={(e) => setAgreeToPrivacy(e.target.checked)} />
                    <Typography>I agree to the privacy policy.</Typography>
                    <Button onClick={handleFacebookLogin} variant="contained" disabled={!agreeToPrivacy}>
                      Continue to Facebook Login
                    </Button>
                  </Box>
                </Modal>
              </Box>
            </React.Fragment>
          )}
        </CardContent>
      </Card>
    </Box>
  );
}
