import React, { useEffect, useState } from 'react';
import { db } from '../../backend/firebase'; 
import { collection, getDocs, updateDoc, doc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom'; 
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button } from '@mui/material';

export default function PremiumUsers() {
  const [premiumUsers, setPremiumUsers] = useState([]);
  const navigate = useNavigate(); 

  useEffect(() => {
    const fetchData = async () => {
      const querySnapshot = await getDocs(collection(db, "premium-users"));
      const usersData = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setPremiumUsers(usersData);
    };

    fetchData();
  }, []);

  const handleCreate = async (userId, pageID) => {
    navigate(`/customize?pageId=${pageID}`);

    const userDocRef = doc(db, "premium-users", userId);
    await updateDoc(userDocRef, { status: 'created' });
    setPremiumUsers(premiumUsers.map(user => user.id === userId ? { ...user, status: 'created' } : user));
  };


  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell>Business Name</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Page ID</TableCell>
            <TableCell>UID</TableCell>
            <TableCell>Download PDF</TableCell>
            <TableCell>Create</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {premiumUsers.map((user) => (
            <TableRow key={user.id} sx={{ bgcolor: user.status === 'pending' ? '#ffcccb' : '' }}>
              <TableCell>{user.businessName}</TableCell>
              <TableCell>{user.status}</TableCell>
              <TableCell>{user.pageID}</TableCell>
              <TableCell>{user.id}</TableCell>
              <TableCell>
                <Button variant="outlined" href={user.fileUrl} target="_blank">Download PDF</Button>
              </TableCell>
              <TableCell>
                <Button variant="contained" onClick={() => handleCreate(user.id, user.pageID)} disabled={user.status !== 'pending'}>Create</Button>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}