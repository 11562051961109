import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithRedirect, signInWithEmailAndPassword, onAuthStateChanged,signInWithPopup } from "firebase/auth";
import { doc, getDoc } from "firebase/firestore";
import { auth, db } from '../../backend/firebase';
import Navbar from '../../components/Navbar/Navbar'; 


// Material UI components
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { Link as RouterLink } from 'react-router-dom';
import GoogleIcon from '@mui/icons-material/Google';
import NotificationCard from '../../components/NotificationCard/NotificationCard'; 

const defaultTheme = createTheme();
const googleProvider = new GoogleAuthProvider();

export default function SignIn() {
  const navigate = useNavigate();
  const [isSigningIn, setIsSigningIn] = useState(false);
  const [userInitiatedSignIn, setUserInitiatedSignIn] = useState(false); // New state to track user-initiated sign-in attempts
  const [notification, setNotification] = useState({ open: false, message: '', type: '' });


  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user && userInitiatedSignIn) { 
        
        // Reset userInitiatedSignIn to false for future sign-in attempts
        setUserInitiatedSignIn(false);
        // Only proceed if the sign-in was initiated by the user
        // Check if the user is the admin based on UID
        if (user.uid === "Dw0beJEWDoMtMoR1jPsMqkxqsXx2") {
          // Redirect the admin user to the admin dashboard
          navigate('/a6ai-admin');
        } else {
          // For other users, fetch their document from Firestore to determine the redirect logic
          const userDocRef = doc(db, "users", user.uid);
          const userDocSnap = await getDoc(userDocRef);
  
          if (userDocSnap.exists()) {
            const userData = userDocSnap.data();
            // Redirect logic based on user data for non-admin users
            if (!userData.hasPaid) {
              navigate('/payment');
            } else if (!userData.isConnectedToFacebook) {
              navigate('/connect-facebook');
            } else if (!userData.hasCreatedChatbot) {
              navigate('/customize');
            } else {
              navigate('/dashboard');
            }
          }
        }
      }
    });
  
    return () => unsubscribe();
  }, [navigate, userInitiatedSignIn]); // Add userInitiatedSignIn as a dependency
  
  const signInWithGoogle = () => {
    setNotification({ open: true, message: 'Signing in...', type: 'loading' });

    setIsSigningIn(true);
    setUserInitiatedSignIn(true); // Set to true when user clicks the "Sign in with Google" button

    signInWithPopup(auth, googleProvider)
      .then((result) => {
        // The signed-in user info.
        const user = result.user;
        // Additional sign-in logic here if needed
      })
      .catch((error) => {
        console.error('Error signing in with Google:', error);
        setNotification({ open: true, message: error.message, type: 'message' });

      })
      .finally(() => {
        setIsSigningIn(false);
      });
  };

  const navigateBasedOnUserStatus = async (user) => {
    if (user) {
      if (user.uid === "Dw0beJEWDoMtMoR1jPsMqkxqsXx2") { // Check if the user is the admin
        navigate('/a6ai-admin');
      } else {
        const userDocRef = doc(db, "users", user.uid);
        const userDocSnap = await getDoc(userDocRef);
  
        if (userDocSnap.exists()) {
          const userData = userDocSnap.data();
          if (!userData.hasPaid) {
            navigate('/payment');
          } else if (!userData.isConnectedToFacebook) {
            navigate('/connect-facebook');
          } else if (!userData.hasCreatedChatbot) {
            navigate('/customize');
          } else {
            navigate('/dashboard');
          }
        }
      }
    }
  };

  
  const handleSubmit = (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    setIsSigningIn(true);
    setNotification({ open: true, message: 'Signing in...', type: 'loading' });
  
    signInWithEmailAndPassword(auth, data.get('email'), data.get('password'))
      .then((result) => {
        setIsSigningIn(false);
        setNotification({ open: false }); // Close the notification on success
        navigateBasedOnUserStatus(result.user); // Now using the same post-sign-in logic as Google sign-in
      })
      .catch((error) => {
        setIsSigningIn(false);
        setNotification({ open: true, message: error.message, type: 'message' }); // Show error message
        console.error('Error signing in with email and password:', error);
      });
  };
  

  return (
    <ThemeProvider theme={defaultTheme}>
      <Navbar />

      <div style={{ background: 'linear-gradient(to left bottom, #882691, #762283, #651d74, #551866, #451357, #3f0e48, #370b3b, #2f082e, #280821, #200516, #16020b, #000000)', minHeight: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Box sx={{ backgroundColor: 'rgba(255, 255, 255, 0.1)', padding: '20px', borderRadius: '16px', display: 'flex', flexDirection: 'column', alignItems: 'center', maxWidth: '400px' }}>
          <CssBaseline />
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}><LockOutlinedIcon /></Avatar>
          <Typography component="h1" variant="h5" style={{ color: 'white' }}>Sign in</Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
              InputProps={{
                style: {
                  color: 'white',
                  backgroundColor: 'transparent', // Set background to transparent
                  border: '1px solid white'
                },
                // Ensure background stays transparent even when the input is focused
                onFocus: (e) => e.target.style.backgroundColor = 'transparent',
                onBlur: (e) => e.target.style.backgroundColor = 'transparent'
              }}
              InputLabelProps={{ style: { color: 'white' } }}
            />

            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
              InputProps={{
                style: {
                  color: 'white',
                  backgroundColor: 'transparent', // Same for the password field
                  border: '1px solid white'
                },
                // Same as above, to maintain transparency
                onFocus: (e) => e.target.style.backgroundColor = 'transparent',
                onBlur: (e) => e.target.style.backgroundColor = 'transparent'
              }}
              InputLabelProps={{ style: { color: 'white' } }}
            />

            <FormControlLabel control={<Checkbox value="remember" color="primary" sx={{ color: 'white', borderColor: 'white' }} />} label="Remember me" style={{ color: 'white' }} />
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2, backgroundColor: 'white', color: 'black' }}>Sign In</Button>
            <Typography variant="body2" align="center" sx={{ mt: 2, color: 'white' }}>Or continue with</Typography>
            <Button fullWidth variant="contained" onClick={signInWithGoogle} startIcon={<GoogleIcon style={{ color: 'white' }} />} sx={{ mt: 2, mb: 1, backgroundColor: 'red', color: 'white', '&:hover': { backgroundColor: 'darkred' } }}>Google</Button>
            <Grid container>
              <Grid item xs><Link href="#" variant="body2" style={{ color: 'white' }}>Forgot password?</Link></Grid>
              <Grid item><RouterLink to="/signup" style={{ textDecoration: 'none', color: 'white' }}><Typography variant="body2">{"Don't have an account? Sign Up"}</Typography></RouterLink></Grid>
            </Grid>
          </Box>
        </Box>
      </div>
      {notification.open && <NotificationCard type={notification.type} message={notification.message} onClose={() => setNotification({ open: false })} />}

    </ThemeProvider>
  );
}
