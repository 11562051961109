import React from 'react';
import './FeatureCard.scss'; 

const FeatureCard = ({ icon, title, text }) => {
  return (
    <div className="feature-card">
      <div className="icon-section">
        
        {typeof icon === 'string' ? <img src={icon} alt="Feature Icon" /> : icon}
      </div>
      <div className="content-section">
        <h3>{title}</h3>
        <p>{text}</p>
      </div>
    </div>
  );
};

export default FeatureCard;
